$client-color-1: #fcea17;
$client-color-2: #ddd;
$borderRadius: 10px;
.UserListWidget {
  text-align: left;
  .widget-liste-new-row {
    border-radius: $borderRadius;
    background-color: #fff;
    padding: 16px;
    margin-bottom: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    .item-row-col,
    .freetext {
      margin: 8px 0;
    }
    .item-row-col.year,
    .freetext {
      width: 100%;
    }
    .wlnr-input {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      column-gap: 8px;
      
      .wlnr-text-input {
        flex-grow: 1;
      }
      .wlnr-ok {
        line-height: 27px;
      }
    }
    label {
      display: block;
      font-weight: bold;
      color: #000;
      width: 100%;
    }
    input {
      border-radius: $borderRadius;
      background-color: #fff;
      border: 1px solid #000;
      padding: 4px;
    }
    textarea {
      margin-top: 0px !important;
    }
    
  }
  .widget-liste-items {
    color: #000;
    background-color: #fff;
    border-radius: 10px;
    &>.item-row,
    &>.wl-item {
      padding: 0 10px;
      // background-color: #fff;
      margin-bottom: 10px;
      
    }
    &>.wl-item:not(:last-child) {
      position: relative;
      &:after {
        border-bottom: 1px solid #ddd;
        content: '';
        position: absolute;
        bottom: 0;
        left: 16px;
        right: 16px;
        // display: block;
      }
      .item-row {
        //border-bottom: 1px solid #ddd;
      }
    }
    
    .wl-item {
      .down-holder {
        opacity: .1;
      }
      .w3-dropdown-content {
        bottom: 100%;
      }
      w3-button {
        padding: 4px 10px;
      }
    }
    .wl-item:nth-child(0),
    .wl-item:nth-child(1),
    .wl-item:nth-child(2) {
      .up-holder {
        opacity: .1;
      }
      .w3-dropdown-content {
        bottom: auto;
      }
    }
    
    .item-row {
      display: flex;
      width: 100%;
      padding-top: 10px;
      column-gap: 10px;
      flex-wrap: wrap;
      // justify-content: space-between;
      .item-row-col {
        display: inline-block;
        min-width: 50px;
        text-align: center;
      }
      .mainValue {
        display: block;
        flex-grow: 4;
        padding: 5px 8px 0 8px;
        word-break: break-all;
        inline-size: min-content;
      }
      .year {
        padding: 8px 0 8px 0;
        .year-content {
          width: 100%;
          background-color: $client-color-2;
          display: block;
        }
        input {
          max-width: 100%;
        }
        .primary-button {
          display: none;
        }
      }
      .freetext {
        flex-basis: 100%;
        border-radius: 8px;
        margin-bottom: 19px;
        overflow: hidden;
        // max-height: 0px;
        transition: .5s max-height .1s ease-in-out;
        .freetext-content {
          background-color: $client-color-2;
          padding: 8px;

        }
        textarea {
          min-width: auto;
          width: auto;
          flex-grow: 2;

        }
      }
      &:hover .freetext {
        max-height: 1000px;
      }
      .check {
        padding-top: 6px;
      }
      .scale {
        .w3-dropdown-hover {
          // width: 62px;
          background-color: $client-color-2;
          margin-bottom: 10px;
          border-radius: $borderRadius;
          &> .w3-button {
            color: #000;
            background-color: $client-color-2 !important;
            border-radius: $borderRadius;
          }
        }
        .w3-bar-item{
          display: flex;
          align-items: center;
        }
      }
      .trash-holder,
      .touch-only {
        padding-top: 6px;
        cursor: pointer;
      }
      .noDisplay ~ .trash-holder,
      .noDisplay ~ .touch-only {
        display: none;
      }
      .entryInput {
        display: flex;
        column-gap: 10px;
        input {
          flex-grow: 2;
        }
        textarea {
          margin-top: 0px !important;
        }
      }
    }
    .dragging {
      opacity: 0;
    }
    .wl-dropzone {
      &::before,
      &::after {
        content: '';
        display: block;
        max-height: 0px;
        transition: .2s max-height ease-out;
        height: 50px;
      }
      &.show-drop-zone-before::before {
        max-height: 30px;
        background-color: #eee;
      }
      &.show-drop-zone-after::after {
        max-height: 30px;
        background-color: #eee;
      }
    }
  }
  &.itemLayout-card {
    .mainValue {
      width: 100%;
    }
  }
  &.itemLayout-oneline {
    .item-row {
      .mainValue {
        flex-basis: 50%;
      }
    }
  }
}
.widget-liste-new-row {
  background-color: #fff;
  color: #000;
  padding: 15px 18px 17px 16px;
  border-radius: 12px;
  box-shadow: 10px 10px 20px 0 rgba(0, 0, 0, 0.1);
  text-align: left;
  input,
  textarea {
    width: 100%;
    border-radius: 8px;
    border: solid 1px #000;
    margin: 12.9px 0 19.1px;
    padding: 8px;
  }
  .wlnr-ok {
    display: inline-block;
  }
  .wlnr-ok button {
    padding: 8px 19px;
  }
  
  .wlnr-new-headline {
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
  }
  
  .wlnr-new-label {
    // height: 27px;
    margin: 9.1px 8px 12.9px 0;
    font-size: 22px;
    font-weight: 600;
    line-height: 1.14;
  }
  .item-row-col.scale  {
    display: inline-block;
    margin-bottom: 13px;
  }
}


.scaleButtonActiveItem {
  background-color: $client-color-1;
}

@import '../../static/styles/vars';

.editMode,
.adminToolBar {
  display: none;
  text-align: left;
}
.pageElement-index-0 .adminToolBar {
  margin-top: 60px;
}
// Layer3: Element
// Leyer2: Page
// Layer1: DoublePage

.admin.i-layer-3 .pageElement {
  &.focus-0 {
    border: 1px solid transparent;
  }
  &:hover,
  &.focus-1 {
    border: 1px dashed #aaa;
    cursor: pointer;
  }
  &.focus-1 {
    .displayMode {
      display: none;
    }
    .editMode {
      display: block;
    }
  }
}

.admin.i-layer-2 .page,
.admin.i-layer-3 .pageElement {
  &.focus-0 {
    border: 1px solid transparent;
  }
  &:hover,
  &.focus-1 {
    border: 1px dashed #aaa;
    cursor: pointer;
  }
  &.focus-1 {
    &> .adminToolBar {
      display: block;
    }
  }
}

.addBlockRoot {
  display: none;
}
.i-layer-4 .page > .addBlockRoot,
.i-layer-4 .pageElement ~ .addBlockRoot {
  display: block;
  z-index: 3;
}

.admin.i-layer-1 .DoublePageContent > .editMode.adminToolBar {
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -200px;
  width: 400px;
  overflow: hidden;
  max-height: 30px;
  opacity: 0.1;
  &:hover {
    max-height: 1000px;
    opacity: 1;
  }
}

.color-box {
  margin: 2px;
  border: 1px solid #000;
  cursor: pointer;
  padding: 1px 10px;
  &:hover {
    border: 1px dashed #666;
  }
  &.color-box-mode-fontColor {
    text-shadow: 0 0 2px #000;
  }
  &.color-box-mode-backgroundColor {
    text-shadow: 0 0 4px #fff;
  }
}

button {
  cursor: pointer;
}

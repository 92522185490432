@import '../../static/styles/vars';

$iconEdgeWidth: 28px;
.userDashMainMenu {
  // Neu ->
  font-size: $header-menu-bar-font-size;
  line-height: $header-menu-bar-line-height;
  min-height: $header-menu-height;
  text-align: center;
  top: 0;
  .home-item {
    float: left;
    margin-left: $global-padding !important;
  }
  .inlineBlock {
    display: inline-block;
  }
  .linkButton {
    font-weight: 600;
    margin: 0 8px;
  }
  .w3-dropdown-hover:hover {
    &:after {
      content: '';
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      width: 300px;
    }
  }
  .lwb-dropdown-hover {
    position: static;
    display: inline-block;
    cursor: pointer;
    .w3-dropdown-hover:hover > .w3-button:first-child {
      color: #000;
      background-color: #ccc;
    }
    &.dropDownActive.opened {
      .w3-dropdown-content {
        // max-height: 1000px !important;
        overflow: auto;
      }
    }
    .w3-dropdown-content {
      overflow: hidden;
      display: block;
      max-height: 0;
      transition: .5s max-height ease-in;
      border-radius: 0 0 0 $dashboard-tile-border-radius;
      max-width: 100%;
      right: 0 !important;
      text-align: left;
      top: $header-menu-height;
      width: 300px;
      transition: .5s max-height ease;

      .linkButton {
        margin: 0;
        padding: 0 $dashboard-tile-padding;
        &:hover {
          @include colorSet3;
        }
      }
    }
  }
  .showNavDesktop {
    @media screen and (max-width: $hamburger-breakpoint) {
      display: none !important;
    }
    .liLogo {
      display: none;
    }
    .liName {
      margin: 0;
    }
  }
  .showNavMobile {
    text-align: left;
    width: 100%;
    @media screen and (min-width: $hamburger-breakpoint + 1px) {
      display: none !important;
    }
  }
  .w3-bar-item {
    height: $header-menu-bar-line-height;
    padding: 0;
  }
  // <- Neu

  .linkButton {
    cursor: pointer;
    &:hover,
    &.active {
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }
  .liLogo {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    float: left;
    height: $header-menu-height;
    width: $iconEdgeWidth;
    svg {
      position: relative;
      top: 6px;
      height: $dashboard-main-menu-icon-size;
      width: $dashboard-main-menu-icon-size;
    }
    &.avatar {
      width: $header-menu-height;
      svg {
        top: 0;
      }
    }
  }
  .liName {
    margin-left: 30px;
  }

  .liLogo.dLeaderShipOs {
    background-image: url('../../static/gfx/lubbers-logo-small.svg');
    height: $header-menu-height;
    &+.liName {
      margin-left: $iconEdgeWidth + 30px;
    }
  }

  .loginHider {
    &.hide {
      display: none;
    }
  }

}

@import '../../static/styles/vars';

$top: 50px;
$left: 5px;
$button-edge: 42px;
$teaser-left: 3 * $left + $button-edge;


.fullScreenButton {
  position: absolute;
  right: 50%;
  bottom: 17px;
  width: 40px;
  height: 40px;
  @media (pointer:coarse) {
    display: none;
  }

  .theFullScreenButton {
    @include functionButton;
    cursor: pointer;
    z-index: 3;
    margin-left: -6px;
    animation: initFullScreenButton 5s 1;
    svg {
      transform: translate(7px, 7px);
    }
  }

  .theFullScreenTeaser {
    display: none;
    /*
    animation-delay: 2s;
    animation: initFullScreenTeaser 5s 1;
    background-color: #F5F5DC;
    border-radius: 5px;
    box-shadow: 5px 5px 5px #999;
    font-size: 1.2em;
    height: auto;
    left: -10px;
    line-height: $button-edge;
    opacity: 0;
    padding: 0 15px;
    pointer-events: none;
    position: absolute;
    bottom: $top;
    z-index: 3;
    min-width: 300px;
    .content {
      display: inline-block;
    }
    */
  }
}

@keyframes initFullScreenButton {
  0% {
    transform: rotate3d(0, 0, 0, 30deg);
  }
  46% {
    transform: rotate3d(0, 0, 0, 30deg);
  }
  48% {
    transform: rotate3d(0, 0, 1, 30deg);
  }
  52% {
    transform: rotate3d(0, 0, -1, 30deg);
  }
  54% {
    transform: rotate3d(0, 0, 0, 30deg);
  }
  100% {
    transform: rotate3d(0, 0, 0, 30deg);
  }
}

@keyframes initFullScreenTeaser {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0) rotate3d(0, 0, 0, 30deg);
  }
  10% {
    opacity: 1;
    transform: translate3d(0, 2px, 0);
  }
  15% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
  90% {
    opacity: 1;
    transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 30deg);
  }
  100% {
    transform: translate3d(-100%, 0, 0) rotate3d(1, 1, -1, 60deg);
    opacity: 0;
  }
}

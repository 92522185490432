@import '../../static/styles/vars';


.book-content {
  height: 100%;
  overflow: hidden;
  position: relative;
  .center {
    text-align: center;
  }
  z-index: 1;
  &.i-layer-7 + .footer {
    display: none;
  }
}

h2 {

}

.layout-dark {
  background-color: $client-color-2;
  color: #fff;
}

.layout-primary-color {
  background-color: $client-color-1;
  color: #fff;
  font-weight: bold;
  font-size: 1.2em;
  line-height: 1.2em;
}
.no-pointer-events {
  pointer-events: none;
}

.legalInfo {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 20px;
  bottom: 20px;
  font-size: 12px;
  left: 30px;
  max-width: 35%;
  padding: 0 20px;
  position: absolute;
  a {
    display: inline-block;
    line-height: 40px;
  }
  @media (min-width: 993px) {
    a:not(:last-child) {
      margin-right: 20px;
    }
  }
}

// Manage small things:
@media (max-width: 992px) {
  body {
    strong span {
      font-size: 28px !important;
    }
  }
  .hideOnMobile {
    display: none !important;
  }
}

iframe {
  width: 100%;
}

.LDrawer {
  background-color: #333;
  color: #fff;
  opacity: 0;
  transform: translateX(-3000px);

  width: 100%;
  z-index: 1000;
  &.open {
    opacity: 1;
    transform: translateX(0);
    transition: .4s transform ease, .4s opacity ease;
  }
  &.closed {
    transition: .8s transform ease, .8s opacity ease;
    .loginDialog {
      display: none;
    }
  }
  .LDrawerCloseButton {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

@import './BookAdmin'

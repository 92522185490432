$activeColorBg: blue;
$activeColorFg: #fff;
$hoverColorBg: #aad;
$hoverColorFg: #fff;

.propSwitcher {
  border: 1px solid #ddd;
  // box-shadow: 1px 1px 4px #666;
  margin: 2px;
  color: black;
  .PropSelectorMatrix {
    width: 100%;
    border: 1px solid #eef;
    overflow: hidden;
    .PropSelectorMatrixField {
      width: 33%;
      float: left;
      text-align: center;
      cursor: pointer;
      &.active {
        background-color: $activeColorBg;
        color: $activeColorFg;
      }
      &:hover {
        background-color: $hoverColorBg;
        color: $hoverColorFg;
      }
    }
  }
}

@import '../../static/styles/vars';

.UserWidget {
  @include functionButton;
  left: 50%;
  z-index: 3;
  transform: translate(6px, 0);
  bottom: 17px;
  cursor: pointer;
  svg {
    transform: translate(7px, 7px);
  }
  @media (pointer:coarse) {
    transform: translate(-20px, 0);
  }
}

.rating-icon-type-2022-12-emoji {
  .rating-icon {
    @for $i from 1 through 9 {
      &.rating-icon-#{$i} {
        &:before {
          content: '';
          width: 30px;
          height: 30px;
          display: inline-block;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 30px;
          background-image: url("../../static/gfx/2022-12-emoji/emoji-#{$i}.png");
        }
      }
    }
  }
}
.rating-icon-type-6 .w3-button .emoji {
  letter-spacing: -2px;
}
// @import './w3-additional.scss';
$breakpointS: 360px;
$breakpointM: 601px;
$breakpointL: 993px;
$breakpointXl: 1500px;

$client-color-1: #fcea17;
$client-color-1-contrast: #000;
$client-color-1-contrast-mark: #fff;
$client-color-1-contrast-mark-fg: #000;

$client-color-2: #272d2f;
$client-color-2-contrast: #fff;
$client-color-2-contrast-mark: #fcea17;
$client-color-2-contrast-mark-fg: #000;

$client-color-3: #eaecee;
$client-color-3-contrast: #272d2f;
$client-color-3-contrast-mark: #272d2f;
$client-color-3-contrast-mark-fg: #fff;

$client-color-4: #fff;
$client-color-4-contrast: #272d2f;
$client-color-4-contrast-mark: #272d2f;
$client-color-4-contrast-mark-fg: #fff;

$client-color-bg-light: #f9f9f9;

$footer-height: 100px;
$button-height: 40px;

$header-menu-height: 60px;
$header-menu-bar-font-size: 12px;
$header-menu-bar-line-height: $header-menu-height;
$global-padding: 16px;
$dashboard-main-menu-icon-size: 22px;

$dashboard-main-content-padding-top: 80px;
$dashboard-tile-gap: 50px;
$dashboard-tile-gap-h: 14%;
$dashboard-tile-gap-v: 11.6%;
$dashboard-tile-height: 368px;
$dashboard-tile-padding: $global-padding;
$dashboard-tile-bottom-padding: 60px;
$dashboard-tile-font-size: 14px;
$dashboard-tile-line-height: 18px;
$dashboard-tile-headline-font-size: 22px;
$dashboard-tile-headline-line-height: 24px;
$dashboard-tile-marker-font-size: 18px;
$dashboard-tile-border-radius: 12px;
$dashboard-tile-box-shadow: 10px 10px 40px 0 rgba(0, 0, 0, 0.15);
$dashboard-doubletile-height: $dashboard-tile-height * 2 + $dashboard-tile-gap;
$dashboard-doubletile-padding: $dashboard-tile-padding * 2;
$hamburger-breakpoint: 730px;

$paddingBelowH1: 15px;
$marginAboveH1: 100px;
$fontWeightH1: 600;
$marginBelowH1: 23px;

@mixin colorSet1 {
  background-color: $client-color-1;
  color: $client-color-1-contrast;
}
@mixin colorSet1Mark {
  background-color: $client-color-1-contrast-mark;
  color: $client-color-1-contrast-mark-fg;
}

@mixin colorSet2 {
  background-color: $client-color-2;
  color: $client-color-2-contrast;
  button {
    background-color: #fff;
    padding: 6px;
    margin-right: 4px;
    margin-bottom: 4px;
    border: none;
    &:hover {
      background-color: $client-color-3;
    }
  }
}
@mixin colorSet2Mark {
  background-color: $client-color-2-contrast-mark;
  color: $client-color-2-contrast-mark-fg;
}
@mixin colorSet3 {
  background-color: $client-color-3;
  color: $client-color-3-contrast;
}
@mixin colorSet3Mark {
  background-color: $client-color-3-contrast-mark;
  color: $client-color-3-contrast-mark-fg;
}
@mixin colorSet4 {
  background-color: $client-color-4;
  color: $client-color-4-contrast;
}
@mixin colorSet4Mark {
  background-color: $client-color-4-contrast-mark;
  color: $client-color-4-contrast-mark-fg;
}

@mixin tileOuter {
  //height: $dashboard-tile-height + $dashboard-tile-gap;
  // padding: $dashboard-tile-gap * .5;
  position: relative;
  height: 0;
  padding-top: 127%;
  font-size: 1vw;
  line-height: 1vw;
  @media (max-width: $breakpointM) {
    font-size: 2vw;
    line-height: 2vw;
  }
  @media (min-width: $breakpointL) {
    font-size: .65vw;
    line-height: .65vw;
  }
  @media (min-width: $breakpointXl) {
    font-size: .54vw;
    line-height: .54vw;
  }
}
@mixin tileInner {
  //height: $dashboard-tile-height;
  left: $dashboard-tile-gap-h * .5;
  top: $dashboard-tile-gap-v * .5;
  bottom: $dashboard-tile-gap-v * .5;
  right: $dashboard-tile-gap-h * .5;
  position: absolute;
  border-radius: $dashboard-tile-border-radius;
  .cardTypeString {
    font-size: 12px;
    font-weight: 600;
    margin-left: $global-padding;
    margin-top: $global-padding;
    position: relative;
    z-index: 2;
  }
  .cardContentFullSize {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

@mixin fullAbs {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
}

@mixin tileShadow {
  box-shadow: $dashboard-tile-box-shadow;
}
@mixin tileTitle {
  font-size: 9.6vw;
  line-height: 1.1em;
  font-weight: bold;
  @media (min-width: $breakpointM) {
    font-size: 3.6vw;
  }
  @media (min-width: $breakpointL) {
    font-size: 1.65vw;
  }
  @media (min-width: $breakpointXl) {
    font-size: 1.34vw;
  }
}
@mixin tileTitleBig {
  @media (min-width: $breakpointL) {
    font-size: 4.3vw;
  }
  @media (min-width: $breakpointXl) {
    font-size: 3.2vw;
  }
}
@mixin tileTeaser {
  font-size: $dashboard-tile-font-size;
  line-height: $dashboard-tile-line-height;
}
@mixin functionButton {
  background-color: $client-color-2;
  border-radius: 8px;
  color: #fff;
  border: none;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, .1);
  line-height: $button-height;
  min-height: 40px;
  min-width: 40px;
  position: absolute;
  padding: 0;
  svg {
    width: 26px !important;
    height: 26px !important;
  }
}

@mixin textColumnsTwo {
  column-count: 2;
  column-gap: 2em;
  // column-width: 270px;
  column-rule-style: none;
  column-gap: 6%;
  // column-rule-width: 0;
  // column-rule-color: silver;
}

@media (min-width: $breakpointL) {
  .big-tile {
    &.l6,
    &.xl4 {
      .book,
      .collectibleItemInner {
        left: $dashboard-tile-gap-h * .25 !important;
        top: $dashboard-tile-gap-v * .25 !important;
        bottom: $dashboard-tile-gap-v * .25 !important;
        right: $dashboard-tile-gap-h * .25 !important;
      }
    }
  }
}

@mixin genericButton {
  padding-left: 2 * $global-padding;
  padding-right: 2 * $global-padding;
  border-radius: 8px;
  font-size: .9em;
  font-weight: bold;
  margin-right: 4px;
}

@mixin primaryButton {
  @include genericButton;
  background-color: $client-color-2;
  border: 1px solid $client-color-2;
  color: $client-color-1-contrast-mark;
  &:hover {
    background-color: #262c2e;
  }
}
@mixin secondaryButton {
  @include genericButton;
  background-color: white;
  border: 1px solid $client-color-2;
  color: $client-color-2;
  &:hover {
    background-color: $client-color-bg-light;
  }
}
@mixin genericBox {
  border-radius: 12px;
  box-shadow: 1px 1px 10px rgba(100, 100, 100, .5);
  padding: 10px;
  margin: 10px auto;
  display: block;
  max-width: 60%;
  &.wide {
    max-width: 95%;
  }
}

.booklist {
  height: 100%;
  overflow: auto;
  width: 100%;
  float: left;
  .action {
    float: right;
    cursor: pointer;
  }
  .avatar {
    .bookPicture {
      width: 50px;
      height: 50px;
      background-size: cover;
      background-position: center;
    }
  }
  .tileEdit {
    width: 209px;
    textarea {
      box-sizing: border-box;
      font-family: "Graphik Web";
      font-size: 27.9936px;
      font-weight: 700;
      height: 230px;
      padding: 0;
      width: 100%;
    }
  }
}

.genericEditorBackground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  .dialogBox {
    margin: auto;
    margin-top: 100px;
    padding: 10px;
    width: 60%;
  }
}

.FileList {
  background-color: #fff;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 200;
  height: 100%;
  .includedInBooks {
    font-size: 0.6em;
  }
  .listContainer {
    cursor: pointer;
    width: 38%;
    overflow: auto;
    position: fixed;
    left: 0;
    bottom: 0;
    top: 150px;
    .file-item {
      padding: 10px;
      &.active {
        background-color: #6af;
        color: #fff;
      }
    }
  }
  .file-preview {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 60px;
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
  .displayName {

  }
  .previewContainer {
    background-color: #666;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    border: 1px solid #666;
    bottom: 0;
    box-shadow: 0 0 10px #666;
    color: #fff;
    position: fixed;
    right: 0;
    top: 0;
    width: 62%;
    .description {
      background-color: rgba(0, 0, 0, 0.5);
      color: 000;
      bottom: 0;
      left: 0;
      padding: 10px;
      position: absolute;
      right: 0;
    }
  }
  .fileTag {
    border: 1px solid #eee;
    margin: 1px;
    padding: 2px;
    .fileTagDel {
      position: relative;
      top: 5px;
      opacity: 0;
      transition: .4s opacity ease;
    }
    &:hover .fileTagDel {
      opacity: 1;
    }
  }
}

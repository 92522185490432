@import '../../static/styles/vars';
$barWidth: 20px;

.barHome {
  margin-bottom: 30px;
  text-align: center;
  .barHolder {
    position: relative;
    height: 200px;
    width: 100%;
    .barBackground {
      position: absolute;
      left: 50%;
      top: 0;
      bottom: 0;
      width: $barWidth;
      background-color: $client-color-3;
      transform: translate(-50%, 0);
      .bar {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: $client-color-1;
      }
    }
    .yourValue {
      position: absolute;
      right: 50%;
      left: 0;
      transform: translate(-$barWidth, 12px);
      text-align: right;
    }
  }
}

/*
primary #fcea17
black #000
white #fff
dark #444a4d
light #ebedee
*/
.fuehrungsstilanalyse-theme {
  &.book-content {
    background-color: #fff;
  }
  .set-backgroundColor-standard {
    background-color: #fff;
  }
  .set-backgroundColor-light {
    background-color: #fff;
  }

  .set-backgroundColor-dark {
    background-color: #272d2f;
  }

  .set-backgroundColor-special1 {
    background-color: #fcea17;
  }

  .set-backgroundColor-special2 {
    background-color: #eaecee;
  }

  .set-backgroundColor-special3 {
    background-color: #28BAC7;
  }

  .set-backgroundColor-special4 {
    background-color: #1C2E39;
  }

  .set-fontColor-standard {
    color: #fff;
  }
  .set-fontColor-light {
    color: #fff;
  }

  .set-fontColor-dark {
    color: #272d2f;
  }

  .set-fontColor-special1 {
    color: #fcea17;
  }

  .set-fontColor-special2 {
    color: #eaecee;
  }

  .set-fontColor-special3 {
    color: #28BAC7;
  }

  .set-fontColor-special4 {
    color: #1C2E39;
  }

  h1 {
    position: relative;
    &::after {
      color: #fcea17;
      content: '__';
      font-size: 60px;
      left: 0;
      line-height: 50px;
      position: absolute;
      bottom: 14px;
      width: 100%;
    }
  }

  @media (min-width: 992px) {
    .pageElement-index-0 .pageElement > p:first-child,
    .pageElement-index-0 .displayMode > p:first-child > p:first-child,
    .pageElement-index-0 .adminToolBar {
      margin-top: 138px;
    }
  }


}

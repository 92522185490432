.collectibleListA {
  overflow: auto;
  height: 100%;
  .block {
    display: block;
  }
  .horizontal-spacer {
    margin: 0 6px;
    input {
      margin-right: 4px;
    }
  }
  &.asOverlay {
    background-color: #fff;
    bottom: 0;
    color: #000;
    left: 0;
    padding-top: 50px;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100000;
  }
  .collectible-list-a-nav-bar {
    position: fixed;
    top: 0;
    z-index: 100;
  }
}

.coachPage {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  .time-symbol svg {
    transform: scale(0.7) translateY(8px);
  }
  .buttons {
    border-radius: 10px 10px 0 0;
  }
  .answer-second-row {
    border-width: 4px;
    border-style: solid;
    border-radius: 10px 0 10px 10px;
    padding: 10px;
  }
  .item {
    padding: 10px;
    text-align: center;
    width: 50px;
    &.selected {
      background-color: #fee;
      border-radius: 100%;
      border-width: 2px;
      border-style: solid;
      font-weight: bold;
    }
  }
  .answers {
    display: table;
    width: 100%;
  }
  .answer-container {
    display: table-row;
    .answer-letter,
    .answer-content {
      display: table-cell;
      padding: 10px;
    }
  }
  .distributiontable {
    margin: auto;
  }
}

.coachsingletable, .coachgrouptable {
  margin: auto;
  border-spacing: 0;
  td {
    cell-spacing: 0;
    &:not(:first-child) {
      text-align: center;
    }
    border: 1px solid grey;
  }
  .rowClassHeader td {
    background-color: #fcea17;
    &:not(:first-child) {
      font-weight: bold;
    }
  }
  .rowClassBold td {
    font-weight: bold;
  }
}
.coachsingletable {

}
.coachgrouptable {

}

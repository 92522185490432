@import '../../static/styles/vars';

// TODO: Remove
.collectibleItemA,
.collectibleItem {
  .collectible-content {
    // border: 1px solid #eee;

    p {
      margin: 10px;
      padding: 4px;
    }
    h1, h2, h3, h4, h5 {
      margin: 10px;
      padding: 4px;
    }
    svg {
      border: 2px solid $client-color-1;
      border-radius: 3.5%;
      overflow: hidden;
    }
  }
}
.collectibleItem {
  max-height: 90%;
  max-width: 78%;
  margin: auto;
  @media (min-height: 600px) and (max-width: 1023px) {
    max-width: 100%;
  }
  .titel {
    display: none;
  }
}

.collectible-graphic {
  background-repeat: no-repeat;
  background-position: center;
  &.collectible-bg-cover {
    background-size: cover;
  }
  &.collectible-bg-contain {
    background-size: contain;
  }
  &.collectible-bg-w100p {
    background-size: 100%;
  }
  &.collectible-bg-h100p {
    background-size: auto 100%;
  }
}
.show {

  .collectible-type-flashcard {
    min-height: 300px;
  }
  .collectible-type-poster {
    min-height: 600px;
  }

}

// new ->
.collectible-box {
  @include tileOuter;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.8s;
  .collectibleItemInner {
    @include tileInner;
    @include tileShadow;
    backface-visibility: hidden;
    overflow: hidden;
    padding: $dashboard-tile-padding;
    z-index: 1;
    border: 2px solid $client-color-1;
    svg {
      font-size: initial;
      border-radius: 12px;
    }
    .cardTypeString {
      margin: 0;
    }
  }
  &:hover {
    // box-shadow: 6px 6px 10px #666;
    transform-origin: center;
    transform: scale(1.05);
    transition: .4s transform ease;
    .toggleMore {
      opacity: .4;
    }
  }
  .toggleMore {
    backface-visibility: hidden;
    background-color: $client-color-2;
    border-radius: 8px;
    bottom: $dashboard-tile-padding;
    opacity: 0;
    padding: 3px;
    position: absolute;
    right: $dashboard-tile-padding;
    transition: .4s opacity ease;
    z-index: 3;
    &:hover {
      background-color: $client-color-2;
      opacity: 1;
    }
  }
  .collectibleMore {
    @include colorSet2;
    @include tileInner;
    @include tileShadow;

    backface-visibility: hidden;
    // left: $dashboard-tile-gap * .5;
    padding: $dashboard-tile-padding;
    position: absolute;
    // right: $dashboard-tile-gap * .5;
    // top: $dashboard-tile-gap * .5;
    transform: rotateY(180deg);
    z-index: 2;
    .title {
      font-size: 1.5em;
      font-weight: bold;
      margin-bottom: 24px;
      margin-right: 30px;
    }
    .qrCode {
      display: block;
      margin: auto;
      // width: 100% !important;
      max-width: 100%;
      max-height: 60%;
    }
    button {
      font-size: 1.5em;
      vertical-align: top;
    }
    canvas {
      border: 4px solid #fff;
    }
    .closeButton {
      top: $dashboard-tile-padding;
      right: $dashboard-tile-padding;
      position: absolute;
    }
  }
  .optionbox-bottom-right {
    position: absolute;
    right: $dashboard-tile-padding;
    bottom: $dashboard-tile-padding;
    max-width: 100%;
    button {
      position: relative;
      border: 1px solid #fff;
      background-color: $client-color-2;
      border-radius: 8px;
      color: #fff;
      margin-right: 0;
      margin-left: 4px;
      &:hover {
        background-color: $client-color-2;
        border: 1px solid $client-color-3;
      }
    }
  }
  .showCopyToClipboardNoticeNotice {
    opacity: 0;
    transition: .5s opacity ease;
  }
  &.showCopyToClipboardNotice .showCopyToClipboardNoticeNotice {
    opacity: 1;
  }
  cursor: pointer;
  transition: 1s transform ease, .2s box-shadow ease;
  // transform-origin: left;
  transform-style: preserve-3d;

  &.showMore {
    transform: rotateY(180deg);
    &>.toggleMore {
      display: none;
    }
  }
}

.lightbox {
  .collectible-box {
    transform: scale(1) !important;
  }
  .toggleMore {
    display: none;
  }
}

$dotRadius: 0.5 * 71px;

.widgetDiagramScroller {
  overflow-x: scroll;
  overflow-y: hidden;
}

.widgetDiagram {
  text-align: left;
  .wd-new-button-holder {
    padding: 20px 0;
    button {
      padding: 10px;
    }
  }
  .diagramEntryTitle {
    font-weight: bold;
  }
  .diagramEntryValue {
    font-weight: bold;
    padding: 4px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    border-radius: 10px;
  }
}
.widgetDiagramFooter {
  position: relative;
  height: 26px;
  z-index: 2;
  .diagramEntry {
    position: absolute;
    top: 0;
    text-align: center;
  }
  .wd-dd-holder {
    display: flex;
    justify-content: center;
    gap: 4px;
    padding-bottom: 10px;
    flex-wrap: wrap;
    .wd-edit {
      border-radius: 50px;
      border: none;
      font-size: .5em;
      &:hover {
        background-color: #fff;
      }
    }
    .wd-y-dd, .wd-z-dd {
      &:hover {
        z-index: 10;
      }
      .w3-dropdown-hover {
        border-radius: 50px;
        transform: scale(.8);
        &> .w3-button {
          border-radius: 50px;
        }
      }
      .w3-button {
        padding: 4px;
        min-width: 30px;
      }
    }
  }
}

.widgetDiagramCanvas {
  position: relative;
  padding-top: 40%;
  svg.wd-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;

    .wd-dot-connector {
      fill: none;
      stroke: black;
      stroke-width: 1;
    }
    .wd-middle-line {
      fill: none;
      stroke: rgba(100, 100, 100, 0.1);
      stroke-width: 4;
    }
  }
  .wd-middle-line {
    display: absolute;
    left: 0;
    width: 100%;
    height: 4px;
    top: 50%;
    background-color: rgba(100, 100, 100, 0.1);
  }
  // background-color: azure;
  .diagramEntry {
    position: absolute;
    top: 0;
    // background-color: aliceblue;
    height: 100%;
    text-align: center;
    overflow: visible;
    &:hover {
      z-index: 10;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    
    .wd-entry-highlighter {
      position: absolute;
      left: 0;
      top: 40%;
      width: 100%;
      padding-top: 100%;
      transform: scale(0);
      transition: all .4s ease-in-out;
      background-color: rgba(100, 100, 100, 0);
      border-radius: 100%;
      // filter: blur(10px);
    }
    &:hover {
      .wd-entry-highlighter {
        transform: scale(10);
        background-color: rgba(100, 100, 100, 0.1);
        border-radius: 0;
        filter: none;
      }
    }
  }
  .wd-growing-separator {
    flex-grow: 10;
  }
  .diagramDot {
    width: 100%;
    position: absolute;
    height: 0;
    overflow: visible;
    padding-top: 50%;
    .diagramDotDrawing {
      position: absolute;
      top: 0;
      left: 0;
      border-radius: 100%;
      width: 100%;
      padding-top: 100%;
      background-color: #000;
    }
  }
  .diagramDotSuspension {
    width: 2px;
    position: absolute;
    top: 2px;
    background-color: #fff;
    left: 50%;
    transform: translateX(-1px);
  }
  
}
.wd-overlay {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  padding: 10px;
  padding-bottom: 100px;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 101;
  .w3-close-button-container {
    overflow: hidden;
    margin-top: 100px;
    margin-bottom: 40px;
    button {
      border-radius: 10px;
    } 
  }
  .InputA,
  input {
    width: 100%;
  }
  
}

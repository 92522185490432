@import '../../static/styles/vars';

.UserDashboard {
  height: 100%;
  overflow: auto;
  background-color: $client-color-bg-light;
  .dashMainBar {
    min-height: $header-menu-height;
    position: fixed;
    @include colorSet4;
    z-index: 2;
    width: 100%;
  }
  .dashMainContent {
    padding-top: $header-menu-height;
    height: 100%;
  }
  .dashMainContentContent {
    height: 100%;
    overflow: visible;
  }

  .noData {
    text-align: center;
    margin: auto;
    margin-top: $dashboard-tile-height;
  }
}

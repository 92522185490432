@import '../../static/styles/vars';
.loginFooter {
  position: fixed;
  bottom: 0;
  padding: $global_padding;
}
/*
.login-dialog {
  margin-top: 10%;
  .w3-card-4 {
    border-radius: 10px;
    .lu-headline {
      border-radius: 10px 10px 0 0;
    }
  }
  .lu-headline,
  .login-button {
    @include colorSet1;
  }

  .login-button {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
*/

.bookSelector {
  background-color: #eee;
  .bookThumb {
    cursor: pointer;
    transition: .5s max-height ease;
    overflow: hidden;
    max-height: 100px;
    &:hover {
      background-color: #ddf;
    }
    &.active {
      background-color: #aaf;
      color: #fff;
    }
    &.dragging {
      max-height: 0;
      opacity: 0;
    }
  }
}

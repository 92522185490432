$edge: 40px;
.floatIcon {
  background-position: center;
  background-size: contain;
  height: $edge;
  left: -$edge * 0.5;
  position: absolute;
  top: -$edge * 0.5;
  width: $edge;
  z-index: 100;
  /*
  &.icon-A {
    background-image: url('../../static/gfx/A-icon.svg')
  }
  &.icon-B {
    background-image: url('../../static/gfx/B-icon.svg')
  }
  &.icon-C {
    background-image: url('../../static/gfx/C-icon.svg')
  }
  &.icon-D {
    background-image: url('../../static/gfx/D-icon.svg')
  }
  */
}

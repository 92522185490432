$headlineSpace: 40px;
.dropzone {
  min-width: 40px;
  min-height: 0;
  background-color: #666;
  border: 0 solid #333;
  transition: .5s min-height ease, .5s border ease;
  &.hover {
    background-color: #9f9;
    border-color: #0a0;
  }
  &.canDrop {
    min-height: 20px;
    border-width: 4px;
  }
}

.pageOverviewRoot {
  height: 100%;
  margin-top: 80px;
  padding-bottom: 120px;
  padding-left: 30px;
  >h2 {
    margin: 0;
    padding: 0;
    height:$headlineSpace;
  }
  .pageOverview {
    width: 50%;
    height: 100%;
    padding-bottom: $headlineSpace;
    overflow: auto;
    float: left;
    .doublePageThumb {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }
    .externalBook {
      border: 4px solid #66f;
      border-radius: 8px;
    }
    .doublePageContent {
      border: 1px solid #eee;
      display: block;
      // float: left;
      height: 160px;
      margin: 2px;
      overflow: hidden;
      padding: 4px;
      position: relative;
      // width: 90px;
      .info {
        display: none;
      }
      .peek {
        border: 1px solid #eee;
        padding: 2px;
      }
      .peek,
      .peek h1 {
        font-size: 10px !important;
        h1 {
          margin: 0;
        }
      }
      .interaction {
        background-color: #fff;
        bottom: 0;
        left: 0;
        position: absolute;
        width: 100%;
        button {
          padding: 2px;
          line-height: 10px;
          font-size: 10px;
        }
      }
    }
  }

  .bookSelector {
    width: 50%;
    height: 100%;
    overflow: auto;
    float: left;
    padding-bottom: $headlineSpace;
  }
  button.active {
    border: 2px solid green;
  }
}

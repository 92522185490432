.admin-bookselector {
  .w3-dropdown-content {
    max-height: 400px;
    overflow-y: auto;
    .book-ddi {
      transition: max-height .4s ease-in-out, padding .4s ease-in-out, border .4s ease-in-out;
      max-height: 100px;
      &.hide {
        max-height: 0;
        padding-top: 0;
        padding-bottom: 0;
        border: none !important;
      }
    }
  }
}
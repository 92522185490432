@import './vars';
@import './messagesboxes';
@import './themes/fuehrungsstil-v1';
@import './themes/standard-theme-2021-01.scss';
@import './themes/standard-theme-2021-09.scss';
@import './w3-additional.scss';
@import './themes/quotebox.scss';
@import './ratingItemes.scss';

@font-face {
  font-family:'Graphik Web';
  font-display:swap;
  src:url("../fonts/Graphik-Light-Web.woff2") format("woff2");
  font-weight:300;
  font-style:normal;
  font-stretch:normal;
}

@font-face {
  font-family:'Graphik Web';
  font-display:swap;
  src:url("../fonts/Graphik-Semibold-Web.woff2") format("woff2");
  font-weight:600;
  font-style:normal;
  font-stretch:normal;
}

@font-face {
  font-family:'Graphik Web';
  font-display:swap;
  src:url("../fonts/Graphik-Medium-Web.woff2") format("woff2");
  font-weight:500;
  font-style:normal;
  font-stretch:normal;
}

@font-face {
  font-family:'Graphik Web';
  font-display:swap;
  src:url("../fonts/Graphik-Regular-Web.woff2") format("woff2");
  font-weight:400;
  font-style:normal;
  font-stretch:normal;
}

@font-face {
  font-family:'Overpass';
  font-display:swap;
  src:url("../fonts/overpass.woff2") format("woff2");
  font-weight:600;
  font-style:normal;
  font-stretch:normal;
}

$noSlack: 3px;
@keyframes sayno {
  0% {transform:translate3d(0%, 0, 0);}
  1% {transform:translate3d(-10%, 0, 0);}
  2% {transform:translate3d(10%, 0, 0);}
  3% {transform:translate3d(-10%, 0, 0);}
  4% {transform:translate3d(10%, 0, 0);}
  5% {transform:translate3d(0%, 0, 0);}
  100% {transform:translate3d(0%, 0, 0);}
}

html {
  overflow: auto !important;
  user-select: none;
}

html, body {
  @media not print {
    height: 100%;
  }
  overflow: hidden;
  font-size: 16px;
  scrollbar-color: $client-color-1 rgba(0,0,0,0);
  scrollbar-width: thin;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &::-webkit-scrollbar,
  &::scrollbar {
    width: 4px;
  }
  &::scrollbar-track,
  &::-webkit-scrollbar-track {
    background: transparent;        /* color of the tracking area */
  }
  &::scrollbar-thumb,
  &::-webkit-scrollbar-thumb {
    background-color: $client-color-1;    /* color of the scroll thumb */
    border-radius: 2px;       /* roundness of the scroll thumb */
    border: 1px solid $client-color-1-contrast-mark-fg;  /* creates padding around scroll thumb */
  }
  @media (max-width: $breakpointXl) {
    font-size: 14px;
  }
}

.noConnection {
  position: fixed;
  right: 120px;
  top: 30px;
  stroke: red;
  transform: scale(3);
  z-index: 1000;
}
.zindex {
  z-index: 100;
}
p {
  margin: 0 0;
}
.book-content {
  p {
    margin-top: 0px;
    margin-bottom: 32px;
  }
  h1 {
    font-size: 33px;
    margin-bottom: $marginBelowH1;
  }
  h2 {
    margin-bottom: 0;
    font-size: 18px;
  }
  // Set special styles:
  h1, h2  {
    margin-top: 50px;
    padding-bottom: $paddingBelowH1;
    position: relative;
    font-weight: $fontWeightH1;
  }
}
// set fonts:
h1, h2, h3, h4, h5, h6, body {
  font-family: 'Graphik Web';
}

.m12:not(.l6) {
  h1, h2 {
    &:after {
      left: 50% !important;
      transform: translate(-50%, 0) !important;
    }
  }
}
#root {
  height: 100%;
  overflow: hidden;
  position: relative;
  /*
  @media print {
    page-break-inside: avoid;
  }
  */
}

.footer {
  bottom: 0;
  height: 0;
  left: 0;
  overflow: visible;
  position: absolute;
  right: 0;
  z-index: 120;
  .button {
    box-shadow: 1px 1px 5px #666;
    cursor: pointer;
    transition: .2s transform ease-in;
    &:hover {
      transform: translate(2px, 2px);
    }
    &.floatLeft {
      position: absolute;
      left: 20px;
      bottom: 20px;
      border-radius: 100%;
    }
  }
  .centerButton {
    @include functionButton;
    bottom: 17px;
    left: 50%;
    margin-left: 57px;
    transition: background .2s ease-in, transform .1s ease-in;
    &:hover:not(.disabled) {
      // border: 2px solid $client-color-1;
      // transition: background .2s ease-out, transform .1s ease-out;
      // transform: translate(-50%, 2px);
      cursor: pointer;
    }
    &.disabled {
      background-color: #eaeced;
      &:hover {
        animation: sayno 8s;
        animation-delay: 1s;
        animation-iteration-count: 4;
      }
    }
    &:focus {
      outline: none;
    }
    svg {
      transform: translate(2px, 7px);
    }
  }
  .button.back-button {
    @include functionButton;
    bottom: 17px;
    right: 50%;
    transform: translate(-57px, 0);
    svg {
      transform: translate(6px, 7px);
    }
  }
}


.floatLeft {
  float: left;
}
.floatRight {
  float: right;
}
.textLeft {
  text-align: left;
}
.textCenter {
  text-align: center;
}
.textRight {
  text-align: right;
}
.inline {
  display: inline;
}
.inline-block {
  display: inline-block;
}

.pointer {
  cursor: pointer;
}

.addBlockRoot {
  text-align: center;
  height: 0;
  overflow: visible;
  position: relative;
  border-bottom: 2px solid #373;
  .addBlock {
    position: absolute;
    display: inline-block;
    top: -20px;
    margin: auto;
    border: 2px solid #373;
    border-radius: 5px;
    padding: 4px;
    z-index: 30;
    width: 120px;
    margin-left: -60px;
  }
}
.addBlock {
  &::before {
    content: '+';
    color: #aba;
  }
  background-color: #efe;
  color: #aba;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  &:hover {
    color: #fff;
    background-color: #6a6;
    &::before {
      color: #fff;
    }
  }
}
.addBlockRoot {
  margin-top: 50px;
}

.hideButton {
  opacity: 0;
}
div:hover > .hideButton,
.addBlockRoot:hover {
  opacity: 1;
}
.adminToolBar {
  margin: 4px;
  padding: 2px;
  background-color: #eee;
  border-radius: 5px;
  border: 1px solid #ddd;
  font-size: .9em;
  position: relative;
  z-index: 101;
  .tool {
    cursor: pointer;
    font-size: 0.8em;
    margin-right: 4px;
    position: relative;
    top: 2px;
    background-color: transparent;
    border: none;
    &:hover {
      background-color: #ccc;
      color: #fff;
    }
  }
}

textarea:not(.uncommon) {
  min-height: 100px;
  min-width: 100%;
  width: 100%;
}

.doublePageEndless {
  padding-bottom: 44px;
  border-bottom: 10px dotted blue;
}
.doublePageSlider {
  bottom: 0;
  left: 0;
  overflow-x: hidden !important;
  position: absolute;
  right: 0;
  top: 0;
  &.scrolling-ok {
    overflow: auto;
  }
  &.no-scrolling {
    overflow: hidden;
  }
  // scrollbar-color: $client-color-1 transparent;
  // scrollbar-width: thin;
  &.smooth {
    transition: .5s transform ease-out;
  }
  // transition: .5s transform ease-out;
  h1, h2, h3, h4, h5, h6, p, .poster, textarea, table, .bullet, li, .rc-canvas, .collectibleItem {
    opacity: 1;
  }
  h1 {
    transition: .5s opacity ease .5s, .5s transform ease .5s;
  }
  h2 {
    transition: .5s opacity ease .6s, .5s transform ease .6s;
  }
  h3 {
    transition: .5s opacity ease .7s, .5s transform ease .7s;
  }
  h4 {
    transition: .5s opacity ease .8s, .5s transform ease .8s;
  }
  h5 {
    transition: .5s opacity ease .9s, .5s transform ease .9s;
  }
  h6 {
    transition: .5s opacity ease 1s, .5s transform ease 1s;
  }
  /*
  p {
    transition: .5s opacity ease .7s, .5s transform ease .7s;
  }
  */
  @for $i from 0 through 40 {
    p.textholder {
      p:nth-child(#{$i}) {
        transition: .5s opacity ease #{.8 + .1 * $i}s, .5s transform ease #{.8 + .1 * $i}s;
      }
    }
    li:nth-child(#{$i}),
    .bullet-#{$i} {
      transition: .5s opacity ease #{.8 + .1 * $i}s, .5s transform ease #{.8 + .1 * $i}s;
    }
  }
  .poster, textarea, table, .collectibleItem, .rc-canvas {
    transition: .5s opacity ease .9s, .5s transform ease .9s;
  }
  &.toTheLeft {
    transform: translate3d(-100%, 0, 0) !important;
    transition: .5s transform ease-out;
    h1, h2, h3, h4, h5, h6, p.textholder p, .poster, textarea, table, .bullet, li, .rc-canvas, .collectibleItem {
      opacity: 0;
      transform: translate3d(-10%, 0, 0);
    }
    .poster, textarea, table {
      opacity: 0;
    }
  }
  &.toTheRight {
    transform: translate3d(100%, 0, 0) !important;
    transition: .5s transform ease-out;
    h1, h2, h3, h4, h5, h6, p.textholder p, .poster, textarea, table, .bullet, li, .rc-canvas, .collectibleItem {
      opacity: 0;
      transform: translate3d(10%, 0, 0);
    }
    .poster, textarea, table {
      opacity: 0;
    }
  }
  &.currentPage {
    // transform: translate3d(0, 0, 0) !important;
    transition: .5s transform ease-out;
    .background-holder {
      opacity: 1 !important;
      transition-delay: 1s;
      transition-duration: 2s;
    }
  }
  .background-holder {
    opacity: 0 !important;
    transition-timing-function: ease;
    transition-property: opacity;
    transition-duration: .5s;
  }
}


.w3-tag {
  border-radius: 5px;
  background-color: #aa0;
  margin-right: 5px;
  margin-left: 3px;
}

.narrowNumberField {
  width: 42px;
}

.centerBox {
  margin: auto;
}

.underlineHover:hover,
a {
  text-decoration: underline;
  cursor: pointer;
}


.jodit-container:not(.jodit_inline) {
  background-color: transparent !important;
}

.pageElement img {
  max-width: 100%;
  height: auto !important;
}

.errMsg {
  margin: 10px auto;
  display: block;
  text-align: center;
  border: 1px solid red;
  background-color: #faa;
  padding: 6px;
  width: 60%;
}
.successMsg {
  margin: 10px auto;
  display: block;
  text-align: center;
  border: 1px solid #0a0;
  background-color: #cfc;
  padding: 6px;
  width: 60%;
}
.admin-tool {
  background-color: #ddd;
  max-height: 100%;
  overflow: auto;
  padding: 10px;
  button {
    margin: 4px;
  }
  ul {
    background-color: #eee;
    border-radius: 4px;
  }
  .description {
    background-color: #eee;
    border-radius: 12px;
    margin: 12px 0;
    padding: 8px;
  }
  user-select: text !important;
}

.relative {
  position: relative;
}

.h100 {
  height: 100%;
}

.w100 {
  width: 100%;
}
.w40 {
  width: 40%;
}
.w60 {
  width: 60%;
}
.w50 {
  width: 50%;
}

.lightbox {
  background-color: #eee;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 3;
  .lightboxItem {
    height: 100%;
    max-height: 100%;
  }
  // .lightboxItem,
  .collectible-box {
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    padding: 86px 0;
    background-color: $client-color-1;
    width: 100%;
    div.collectibleItemInner {
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      box-shadow: none;
    }
  }
  svg {
    max-height: 100%;
    margin: auto;
    display: block;
    font-size: initial;
  }
  button {
    background-color: $client-color-2;
    border-radius: 8px;
    border: none;
    height: 40px;
    margin: 0 0 30px 10px;
    padding: 9px;
    position: absolute;
    right: 25px;
    top: 25px;
    width: 40px;
  }
}

.big-tile {
  margin-bottom: -1px;
}

.w3-col {
  animation-duration: .5s;
  animation-name: fadeIn;
  &:hover {
    position: relative;
    z-index: 1;
  }
}

.admin .w3-col {
  animation-name: none;
}

.lu-dialog {
  background-color: $client-color-1;
  margin-top: 10%;
  border-radius: 12px;
  color: $client-color-2;
  .lu-headline {
    font-weight: bold;
    font-size: .9em;
    padding-top: $global-padding;
    padding-bottom: $global-padding;
  }
  label {
    font-weight: bold;
    font-size: 1.4em;
  }
  input {
    background-color: #fff !important;
    border-radius: 8px;
    box-sizing: border-box;
    font-size: .9em;
    margin-top: .5 * $global-padding;
    margin-bottom: $global-padding;
  }
  button {
    margin-top: .5 * $global-padding;
    margin-bottom: $global-padding;
    @include primaryButton;
  }
  .w3-panel {
    border-radius: 8px;
  }
}

.padding-right {
  padding-right: $global-padding;
}

.bold {
  font-weight: bold;
}

.collect {
  background-color: rgba(100, 100, 100, .5);
  border-radius: 8px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  margin-top: 2 * $global-padding;
  padding-right: $global-padding;
  position: relative;
  transition: .5s background-color ease;
  &:hover {
    background-color: rgba(100, 100, 100, 1);
  }
  .lu-collect-button {
    background-color: $client-color-2;
    color: $client-color-1-contrast-mark;
    border-radius: 8px;
    font-size: .9em;
    margin-right: 5px;
    width: 40px;
    height: 40px;
    svg {
      transform: translateX(-8px);
    }
  }
}

.scrollY {
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: scale(.9);
  }
  10% {
    opacity: 0;
    transform: scale(.9);
  }
  50% {
    opacity: .5;
    transform: scale(1);
  }
  90% {
    opacity: .9;
    transform: scale(1.01);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.fontSize2 {
  font-size: 2em;
}
.fontSize4 {
  font-size: 4em;
}
.fontSize8 {
  font-size: 8em;
}

.vhide {
  display: block;
  max-height: 0;
  overflow: hidden;
  transition: .4s max-height ease;
  &.show {
    max-height: 2000px;
  }
}

.page.bgPositionTl,
.page.bgPositionTl .background-holder,
.bgPositionTl .DoublePageBackgroundHolder {
  background-position: top left;
}
.page.bgPositionTc,
.page.bgPositionTc .background-holder,
.bgPositionTc .DoublePageBackgroundHolder {
  background-position: top center;
}
.page.bgPositionTr,
.page.bgPositionTr .background-holder,
.bgPositionTr .DoublePageBackgroundHolder {
  background-position: top right;
}
.page.bgPositionMl,
.page.bgPositionMl .background-holder,
.bgPositionMl .DoublePageBackgroundHolder {
  background-position: center left;
}
.page.bgPositionMc,
.page.bgPositionMc .background-holder,
.bgPositionMc .DoublePageBackgroundHolder {
  background-position: center center;
}
.page.bgPositionMr,
.page.bgPositionMr .background-holder,
.bgPositionMr .DoublePageBackgroundHolder {
  background-position: center right;
}
.page.bgPositionBl,
.page.bgPositionBl .background-holder,
.bgPositionBl .DoublePageBackgroundHolder {
  background-position: bottom left;
}
.page.bgPositionBc,
.page.bgPositionBc .background-holder,
.bgPositionBc .DoublePageBackgroundHolder {
  background-position: bottom center;
}
.page.bgPositionBr,
.page.bgPositionBr .background-holder,
.bgPositionBr .DoublePageBackgroundHolder {
  background-position: bottom right;
}

@media (hover: none) {
  // styles to target touch devices
  .desktop-only {
    display: none;
  }
}

@media (hover: hover) {
  // styles to target non-touch devices
  .touch-only {
    display: none;
  }
}

.hide {
  opacity: 0;
}

.noDisplay {
  display: none;
}

.flex {
  display: flex;
  &.flex-column {
    flex-direction: column;
  }
  &.flex-stretch {
    align-content: stretch;
    &>* {
      flex-grow: 2;
    }
  }
  &.flex-gap {
    gap: 8px;
  }
  &.align-items-center {
    align-items: center;
  }
  &.align-items-stretch {
    align-items: stretch;
  }
  &.align-items-top {
    align-items: flex-start;
  }
  &.align-items-bottom {
    align-items: flex-end;
  }
  &.justify-content-center {
    justify-content: center;
  }
  &.justify-content-flex-end {
    justify-content: flex-end;
  }
  &.justify-content-space-between {
    justify-content: space-between;
  }
}
.flex-grow {
  flex-grow: 2;
  overflow: auto;
  flex-shrink: 2;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-middle {
  display: flex;
  align-items: center;
}


// show next engine:
.hover-show-next-target {
  overflow: hidden;
  max-height: 0;
  transition: .4s max-height ease-in-out, .4s opacity ease-in-out, .4s padding ease-in-out;
  width: 100%;
  flex-basis: 100%;
  opacity: 0;
  padding: 0;
}
.hover-show-next:hover + .hover-show-next-target,
.hover-show-next-target:hover {
  max-height: 2000px;
  padding: 16px;
  border: 1px solid #eee;
  box-shadow: 0 10px 10px #aaa;
  opacity: 1;
}

.overflowHidden {
  overflow: hidden;
}

.hover:hover {
  background-color: rgba(250, 231, 23, .5);
  cursor: pointer;
}
.mark {
  background-color: rgba(250, 231, 23, .8);
}

.mh100 {
  max-height: 100%;
  overflow: auto;
}
.mh100noScroll {
  max-height: 100%;
}

.STROOT {
  p {
    margin-bottom: 2px;
  }
}
.w3-dropdown-content {
  z-index: 2;
}

.context-menu {
  min-width: 400px;
  min-height: 200px;
  max-height: 400px;
  overflow: auto;
  max-width: 100%;
}

.blank200 {
  min-height: 200px;
}

.blank300 {
  min-height: 300px;
}

.empty {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 50px;
}

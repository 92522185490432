@import '../../static/styles/vars';

.paragraph {
  color: $client-color-2;
  @media (min-width: $breakpointL) {
    padding: 100px 170px;
  }
  .lu-dialog {
    margin-top: 0;
  }
  &.right.text-and-image {
    .paragraph-text-content {
      @media (min-width: $breakpointL) {
        padding-right: 220px;
      }
      @media (max-width: $breakpointL) {
        padding: 20px 20px;
      }
    }
    .lu-dialog,
    img {
      float: right;
    }
  }
  &.left.text-and-image {
    .paragraph-text-content {
      @media (min-width: $breakpointL) {
        padding-left: 76px;
        padding-right: 76px;
      }
      @media (max-width: $breakpointL) {
        padding: 20px 20px;
      }
    }
  }
  &.text-only.left {
    .paragraph-text-content {
      @media (min-width: $breakpointL) {
        padding-left: 76px;
        padding-right: 172px;
        text-align: right;
      }
      @media (max-width: $breakpointL) {
        padding: 20px 20px;
      }
    }
  }
  &.text-only.right {
    .paragraph-text-content {

      @media (min-width: $breakpointL) {
        padding-left: 172px;
        padding-right: 76px;
      }
      @media (max-width: $breakpointL) {
        padding: 20px 20px;
      }
    }
  }
  h2 {
    font-size: 44px;
    line-height: 1.14em;
    font-weight: 600;
  }
  .paragraph-text-content-content {
    font-size: 28px;
    font-weight: 300;
    line-height: 1.18em;
  }
  &.main-headline {
    padding-top: 0;
    background-color: $client-color-1;
    h2 {
      @media (min-width: $breakpointL) {
        font-size: 120px;
        margin-top: 250px;
      }
      line-height: 1.08em;
      margin-bottom: 34px;
    }
    //.paragraph-image-content {
      // transform: translateY(-20px);
      // z-index: 10;
    //}
  }

  .paragraphLinkButton {
    margin-top: 42px;
    font-weight: bold;
    padding: 18px 24px 18px 24px;
    font-size: 18px;
  }
}

@import '../../static/styles/vars';

.poster {
  background-color: #cc0;
  color: #fff;
  font-size: 2em;
  text-align: center;
  padding-top: 30%;
  padding-bottom: 30%;
  font-weight: bold;
}

.sessionId {
  border-radius: 10px;
  border: 1px solid #000;
  display: inline-block;
  font-size: 1.6em;
  font-weight: bold;
  margin: auto;
  padding: 20px;
}

.adminPageHolder {
  .pageElementHolder {
    position: relative;
    .adminCover {
      display: block;
      height: 100%;
      background-color: rgba(0, 0, 0, 0);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
$mpr: 2.8%;
$mpl: 2.8%;

@mixin manualHalf {
  width: 49.999%;
  // float: left;
  display: inline-block;
  padding-right: $mpr;
  &:not(:last-child) {
    float: left
  }
  + .layout-manualColumns-half {
    padding-right: 0;
    padding-left: $mpl;
    + .layout-manualColumns-half {
      padding-right: $mpr;
      &:not(:last-child) {
        float: left
      }
      + .layout-manualColumns-half {
        padding-right: 0;
        padding-left: $mpl;
        + .layout-manualColumns-half {
          padding-right: $mpr;
          &:not(:last-child) {
            float: left
          }
        }
      }
    }
  }
}
@mixin manualThird {
  width: 33%;
  // float: left;
  display: inline-block;
}
@mixin manualTwoThird {
  width: 66%;
  // float: left;
  display: inline-block;
}

.pageElement {
  .adminToolBar {
    color: #000;
    &>div > .w3-row {
      padding-top: 10px;
      padding-bottom: 10px;
      &:nth-child(odd) {
        background-color: #ddd;
      }
    }
  }

  .adminToolBar .propSwitcher {
    display: inline-block;
  }
}
.pageElementHolder {
  &.layout-manualColumns-half {
    @media (min-width: $breakpointM) {
      @include manualHalf;
    }
  }
  &.layout-manualColumns-third {
    @media (min-width: $breakpointXl) {
      @include manualThird;
    }
    @media (min-width: $breakpointM) and (max-width: $breakpointL) {
      @include manualThird;
    }
  }
  &.layout-manualColumns-twothird {
    @media (min-width: $breakpointXl) {
      @include manualTwoThird;
    }
    @media (min-width: $breakpointM) and (max-width: $breakpointL) {
      @include manualTwoThird;
    }
  }
}

.userList {
  span {
    margin-right: 4px;
  }
  .freeSearch {
    display: inline-block;
    input {
      margin: 1em;
      border-color: #2196F3;
      border-width: 1px;
      line-height: 2em;
    }
  }
  .markAll {
    cursor: pointer;
  }
  .marked {
    border: 1px solid #aaa;
    cursor: pointer;
    display: inline-block;
    float: left;
    height: 50px;
    margin: 10px;
    width: 50px;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    &.active {
      background-color: green;
      background-image: none !important;
    }
  }
  .tag-tool {
    background-color: #eee;
    border-radius: 4px;
    margin: 4px 0;
    padding: 4px;
  }
  .dditem {
    padding: 4px;
    &.active {
      background-color: green;
      color: #fff;
    }
    .x {
      display: inline;
      height: 14px;
    }
  }
  .users {
    bottom: 0;
    left: 0;
    overflow: auto;
    position: absolute;
    top: 42px;
    width: 50%;
  }
  .user {
    background-color: #eee;
    border-radius: 4px;
    margin: 1px 0;
  }
  .books {
    bottom: 0;
    overflow: auto;
    position: absolute;
    right: 0;
    top: 42px;
    width: 50%;
    .userBookItem {
      background-color: #eee;
      border-radius: 4px;
      cursor: pointer;
      margin: 1px;
      padding: 2px 4px;
      &.active {
        background-color: green;
        color: #fff;
      }
      &.some {
        background-color: #aca;
        color: #fff;
      }
    }
  }
  .avatar,
  .avatar img {
    width: 100%;
  }
}

.activateAdminBar {
  background-color: rgba(255, 255, 255, 0.5);
  bottom: 0;
  height: 100%;
  position: fixed;
  top: 0;
  width: 30px;
  z-index: 30;
}
.leftAdminActivator {
  left: 0;
}
.rightAdminActivator {
  display: none;
  right: 0;
}
.adminBar {
  left: 0;
  padding: 10px 10px 10px 30px;
  top: 0;
  transform: translate3d(-100%, 0, 0);
  transition: transform .5s ease;
  z-index: 300;
}
.activateAdminBar:hover ~ .adminBar,
.adminBar:hover {
  transform: translate3d(0, 0, 0);
  transition: transform .5s ease;
}

.tool {
  max-width: 50%;
  width: 50%;
}

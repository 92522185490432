.book-content.standard-theme-2021-09 {
  hyphens: auto;
  &.book-content {
    background-color: $client-color-bg-light;
  }
  h1 {
    @media (min-width: $breakpointL) {
      font-size: 3.5vw;
    }
    font-size: 42px;
    line-height: 1em;
    font-weight: 600;
    margin-top: $marginAboveH1;
    &.hide {
      opacity: 0;
    }
  }
  h2 {
    margin-top: 44px;
    margin-bottom: 52px;
    font-size: 30px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    
  }
  p {
    margin-bottom: 20px;
  }
  .pageElement {
    padding-left: 0;
    padding-right: 0;
  }

  .set-fontColor-standard {
    color: #000;
  }
  .set-fontColor-light {
    color: #fff;
    .widgetDiagramCanvas .diagramDot .diagramDotDrawing {
      background-color: #fff;
    }
    .widgetDiagramCanvas svg.wd-bg .wd-dot-connector {
      stroke: #fff;
    }
  }

  .set-fontColor-dark {
    color: #272d2f;
  }

  .set-fontColor-special1 {
    color: #fcea17;
  }

  .set-fontColor-special2 {
    color: #eaecee;
  }

  .set-fontColor-special3 {
    color: #28BAC7;
  }

  .set-fontColor-special4 {
    color: #1C2E39;
  }

  textarea {
    padding: 10px 19px 2px 13.4px;
    border-radius: 15px;
    background-color: #fff;
    color: #000;
    max-width: 100%;
    // font-size: $global-padding;
    // font-weight: normal;
    // font-stretch: normal;
    // font-style: normal;
    // line-height: 1.33;
    // letter-spacing: normal;
  }

  .doublePage.column-layout-inherit .page.column-layout-inherit,
  .doublePage.column-layout-twoColumn .page.column-layout-inherit,
  .page.column-layout-twoColumn {
    .pageElementHolder:not(.layout-manualColumns-half):not(.layout-manualColumns-third):not(.layout-manualColumns-twothird) .textholder {
      @media (min-width: $breakpointM) {
        @include textColumnsTwo;
      }
    }
  }

  .textholder {
    p {
      // text-align: justify;
      hyphens: auto;
      orphans: 3;
      widows: 3;
    }
  }

  .pageElement-index-0,
  .pageElement-index-0.layout-manualColumns-half + .layout-manualColumns-half,
  .pageElement-index-0.layout-manualColumns-third + .layout-manualColumns-third,
  .pageElement-index-0.layout-manualColumns-third + .layout-manualColumns-third .layout-manualColumns-third,
  .pageElement-index-0.layout-manualColumns-third + .layout-manualColumns-twothird,
  .pageElement-index-0.layout-manualColumns-twothird + .layout-manualColumns-third {
    &.layout-kind-quoteBox,
    textarea:first-child,
    :first-child.callToActionButton,
    :first-child.textholder,
    :first-child.embed,
    :first-child.libraryLinkButton,
    .UserRatingWidget,
    .UserListWidget,
    .tab-holder,
    .col-holder,
    .widgetDiagram {
      // margin-top: 181px;
      &::before {
        content: '→';
        display: block;
        flex-basis: 100%;
        @media (min-width: $breakpointL) {
          font-size: 3.5vw;
        }
        font-size: 42px;
        line-height: 1em;
        font-weight: $fontWeightH1;
        margin-top: $marginAboveH1;
        opacity: 0;

        margin-bottom: $marginBelowH1;
        padding-bottom: $paddingBelowH1;
      }
    }
    h2.extractedHeadline {
      margin-top: 108px;
      margin-bottom: 23px;
    }
    .pageElement>:first-child.magnet {
      margin-top: 181px !important;
    }
    :first-child.collectibleItem {
      margin-top: 106px !important;
    }
    &.layout-kind-pageBox {
      /*
      position: absolute;
      top: 135px;
      bottom: 135px;
      right: 397px;
      left: 169px;
      */
      margin-top: 135px;
      min-height: 600px;
      position: relative;
    }
  }

  .layout-kind-pageBox {
    background-color: $client-color-3;
    padding: 17px 60px 33px 33px;
    border-radius: 12px;

    .pageElement {
      margin-top: auto;
    }
  }

  .layout-kind-hugeText {
    p, h1 {
      font-size: 6vw;
      @media (min-width: $breakpointL) {
        margin-right: -20%;
      }
    }
  }

  $pagePaddingSideOuter: 9.2%;
  $pagePaddingSideInner: 8%;
  $pagePaddingSideOuter-L: $pagePaddingSideOuter * .5;
  $pagePaddingSideInner-L: $pagePaddingSideInner * .5;

  .page-local-index-0.page-padding {
    padding: 0 $pagePaddingSideOuter 0 $pagePaddingSideInner;
    .widgetDiagramScroller {
      margin-left: -$pagePaddingSideInner;
      margin-right: -$pagePaddingSideOuter;
    }
    @media (min-width: $breakpointL) and (max-width: $breakpointXl) {
      padding: 0 $pagePaddingSideOuter-L 0 $pagePaddingSideInner-L;
      .widgetDiagramScroller {
        margin-left: -$pagePaddingSideInner-L;
        margin-right: -$pagePaddingSideOuter-L;
      }
    }
  }
  .page-local-index-1.page-padding {
    padding: 0 $pagePaddingSideInner 0 $pagePaddingSideOuter;
    .widgetDiagramScroller {
      margin-left: -$pagePaddingSideOuter;
      margin-right: -$pagePaddingSideInner;
    }
    @media (min-width: $breakpointL) and (max-width: $breakpointXl) {
      padding: 0 $pagePaddingSideInner-L 0 $pagePaddingSideOuter-L;
      .widgetDiagramScroller {
        margin-left: -$pagePaddingSideOuter-L;
        margin-right: -$pagePaddingSideInner-L;
      }
    }
  }

  .doublePage.no-background .page-local-index-1 .background-holder {
    background-color: $client-color-3;
  }
  .crumbs {
    font-size: 12px;
    left: 17px;
    line-height: 1.6em;
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    top: 17px;
    white-space: nowrap;
    width: 80%;
    z-index: 100;
    @media (max-width: $breakpointS) {
      width: 69%;
    }
    .bookname {
      display: block;
      margin-right: 10px;
      font-weight: 600;
    }
    .crumb {
      font-weight: normal;
      font-style: italic;
      .chapternumber {
        display: none;
      }
      &.last .chapternumber {
        display: inline;
      }
      &:not(.first) {
        &:before {
          content: ' - ';
        }
      }
    }
    &.chapter-style-chapter .chapternumber::after {
      display: inline;
      margin-right: 10px;
      content: ' ';
    }

  }
  .dp-index-1 .crumbs {
    display: none;
  }

  .page {
    position: relative;
    &.page-local-index-1 {
      padding-bottom: 74px;
    }
    &.page-local-index-0 {
      @media (min-width: $breakpointL) {
        padding-bottom: 74px;
      }
    }
    &.set-backgroundColor-special1 {
      // align-items: flex-end;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 12%;
      padding-top: 8%;
      @media (max-width: $breakpointL) {
        padding-bottom: 24%;
        padding-top: 17%;
      }
      .pageElement-index-0 :first-child.textholder {
        margin-top: 0;
      }
      .pageElementHolder {
        width: 100%;
      }
      .textholder {
        column-count: 1 !important;
        font-size: 28px;
        font-weight: 300;
        line-height: 1.18em;
      }
      .background-holder {
        display: block;
        @media (max-width: $breakpointL) {
          width: 100%;
        }
        &::after {
          background-color: #fff;
          border-radius: 12px;
          bottom: 15%;
          content: '';
          left: 13%;
          position: absolute;
          right: $pagePaddingSideOuter;
          top: 10%;
          @media (min-width: $breakpointL) and (max-width: $breakpointXl) {
            left: 6%;
            right: $pagePaddingSideOuter-L;
          }
          @media (max-width: $breakpointL) {
            right: 17% !important;
            left: 0% !important;
          }
        }
        @media (max-width: $breakpointXl) {
          &::after {
            bottom: 14%;
            top: 8%;
          }
        }
      }
      /*
      @media (max-width: $breakpointL) {
        .pageElement {
          background: #fff;
          border-radius: 12px;
        }
      }
      */

    }
    &.set-backgroundColor-special2 {
      .background-holder {
        background-color: $client-color-1 !important;
      }
    }
    &.set-backgroundColor-special3 {
      font-size: 28px;
      font-weight: 300;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.18;
      letter-spacing: normal;
      .background-holder {
        background-color: $client-color-1 !important;
        transform: translateX(-30%);
      }
    }
    &.set-backgroundColor-special4 {
      background-color: #34a8ff;
      color: #fff;
    }
  }
}

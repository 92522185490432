.lubbersLogoHolder {
  background-size: 50px;
  background-repeat: no-repeat;
  background-position: center center;
  height: 60px;
  background-image: url('../../../static/gfx/lubbers-logo-small.svg');
}
.evaluation1-holder {
  max-width: 650px;
  margin: auto;
}

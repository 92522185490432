.TestPage {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: auto;
  .TestTable {
    margin: auto;
    font-size: 2em;
    td {
      border: 1px solid #eee;
      padding: 4px;
    }
  }
}

@import '../../static/styles/vars';

$theDistance: 18px;
$hamWidth: 22px;
$hamMargin: 14px;
$tb: 5px;
$duration: .5s;
$tocWidth: 300px;
.toc-hamburger {
  background-color: rgba(255, 255, 255, .2);
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, .05);
  cursor: pointer;
  height: $hamWidth + 2 * $hamMargin;
  position: absolute;
  right: $theDistance;
  top: $theDistance;
  width: $hamWidth + 2 * $hamMargin;
  z-index: 123;
  .toc-icon {
    height: $hamWidth;
    position: absolute;
    right: $hamMargin;
    top: $hamMargin;
    transition: .5s opacity ease;
    width: $hamWidth;
  }
  .close {
    opacity: 0;
  }
  &.active {
    .close {
      opacity: 1;
    }
    .burger {
      opacity: 0;
    }
  }
}

.toc-content-hider {
  background-color: rgba(255, 255, 255, 0.5);
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: .5s opacity ease;
  z-index: 2;
  &.active {
    opacity: 1;
    pointer-events: auto;
    z-index: 121;
  }
}

.toc-content {
  background-color: #fff;
  bottom: 0;
  box-shadow: 0 0 5px #eee;
  padding-bottom: 135px;
  padding-top: $hamWidth + 2 * $hamMargin;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX($tocWidth);
  transition: .5s transform ease;
  width: $tocWidth;
  z-index: 122;
  @media (max-width: $breakpointM) {
    width: 100%;
    transform: translateX(100%);
  }
  &.active {
    transform: translateX(0);
  }
  .toc-content-content {
    box-shadow: inset 0px -10px 10px -10px #eee;
    max-height: 100%;
    overflow-y: auto;
    .toc-section {
      cursor: pointer;
      position: relative;
      font-weight: bold;
      padding: $hamMargin * .5 $hamMargin $hamMargin * .5 $hamMargin * 2;
      transition: .3s background-color ease;
      &:hover {
        background-color: yellow;
      }
    }
    .toc-subsections {
      .toc-subsection,
      .toc-subsubsection,
      .toc-subsubsubsection {
        cursor: pointer;
        position: relative;
        font-size: 0.9em;
        transition: .3s background-color ease;
        &:hover {
          background-color: yellow;
        }
      }
      .toc-subsection {
        padding: $hamMargin * .5 $hamMargin $hamMargin * .5 $hamMargin * 2.5;
      }
      .toc-subsubsection {
        padding: $hamMargin * .5 $hamMargin $hamMargin * .5 $hamMargin * 3;
      }
      .toc-subsubsubsection {
        padding: $hamMargin * .5 $hamMargin $hamMargin * .5 $hamMargin * 3.5;
      }
    }
  }
}

.chapter-style-clean {
  .chapternumber {
    display: none !important;
  }
}
.chapter-style-number {
  .chapternumber {
    &::after {
      content: ' ';

    }
  }
}
.chapter-style-chapter {
  .chapternumber {
    &::before {
      content: 'Kapitel ';
    }
    &::after {
      content: '';
      display: block;
    }
  }
}
.interactive-icon {
  width: 13px;
  height: 13px;
  top: 11px;
  background-color: $client-color-1;
  display: inline-block;
  position: absolute;
  left: 5px;
}
@import '../../static/styles/vars';

.ProgressBar {
  background-color: $client-color-2;
  border-radius: 10px;
  color: #fff;
  font-size: 12px;
  left: 17px;
  padding: 8px 6px;
  position: absolute;
  z-index: 1;
  line-height: 8px;
  @media (max-width: $breakpointS) {
    display: none;
  }
  @media (max-width: $breakpointM) {
    bottom: 27px;
    left: 15px;
  }
  @media (min-width: $breakpointM - 1px) {
    bottom: 25px;
  }
  .BarHolder {
    background-color: $client-color-bg-light;
    border-radius: 4px;
    display: inline-block;
    height: 8px;
    margin-right: 4px;
    overflow: hidden;
    position: relative;
    width: 62px;
    @media (max-width: 490px) {
      display: none;
    }
    .Bar {
      background-color: $client-color-1;;
      left: 0;
      position: absolute;
      top: 0;
      height: 8px;
    }
  }
  .ProgressBarLabel {
    display: inline;
  }
}

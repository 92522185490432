@import '../../static/styles/vars';
.page {
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  // padding: 10px 10px 80px 10px;
  padding-bottom: 72px;
  // overflow: hidden;
  &:last-child {
    // padding-bottom: 90px;
  }
  &> div {
    position: relative;
    z-index: 2;
    &:hover {
      z-index: 4;
    }
  }
  @media (min-width: $breakpointL) {
    background-image: none !important;
  }
  .background-holder {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: none;
    height: 100%;
    pointer-events: none;
    position: absolute !important;
    top: 0;
    width: 50%;
    z-index: 0;
  }
  @media (max-width: ($breakpointL - 1px)) {
    div.background-holder {
      display: block;
      width: 100%;
      left: 0;
    }
  }
  @media (min-width: $breakpointL) {
    &.page-local-amount-1 div.background-holder {
      width: 100%;
    }
    div.background-holder {
      display: block;
      position: fixed !important;
      width: 50%;
      pointer-events: none;
    }
    &.page-local-index-0:not(.single-page) {
      float: left;
      width: 50%;
      .background-holder {
        left: 0;
      }
    }
    &.page-local-index-1 {
      float: left;
      width: 50%;
      .background-holder {
        right: 0;
      }
    }
    /*
    &.page-desktop-fixed {
      position: fixed;
    }
    */
  }

  @media (max-width: $breakpointL) {
    &.blank-page {
      display: none;
    }
  }
}

@media (min-width: $breakpointL) {
  .page-local-index-0 {
    .background-holder {
      display: block;
      left: 0;
    }
  }
  .page-local-index-1 {
    .background-holder {
      display: block;
      right: 0;
    }
  }
}

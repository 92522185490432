.unitdd-type {
  .unitDDItemName {
    position: relative;
    top: -6px;
  }
  .inactive {
    .unitDDItemName {
      cursor: not-allowed;
    }
  }
  &.not-checkboxmode {
    .inactive {
      display: none;
    }
  }
}

.coachPage {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  td {
    padding: 10px;
    border-right: 1px solid #eee;
    border-top: 1px solid #eee;
  }
  .item {
    padding: 10px;
    text-align: center;
    &.selected {
      background-color: #fee;
      border-radius: 100%;
      border: 2px dotted red;
      font-weight: bold;
    }
  }
}

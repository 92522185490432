@import '../../../static/styles/vars';

$cardGridPadding: 0;
$tiltAngle: 10deg;

.board {
  .cardBody {
    // box-sizing: border-box;
    // background-color: rgba(0, 0, 255, 0.5);
    // padding: 8px;
    position: relative;
    transition: transform .3s ease;
    width: 18%;
    z-index: 10;
    // 1-12:
    @for $i from 1 through 12 {
      $random: random(100) - 50;
      &:nth-child(#{$i}) {
        transform: rotate3d(1, 0, $random / 500, $tiltAngle) translate3d(0, -$i * 2px, $i * 5px);
      }
      z-index: 10 + $i !important;
    }
    // 13-100:
    @for $i from 13 through 100 {
      $random: random(100) - 50;
      &:nth-child(#{$i}) {
        transform: rotate3d(1, 0, $random / 500, $tiltAngle) translate3d(0, -12 * 2px, $i * 5px);
      }
      z-index: 10 + $i !important;
    }
    .cardBodyBody {
      padding-top: 130.4%;
      // box-sizing: border-box;
      //align-items: center;
      background-color: #ffc;
      color: #000;
      // background-image: url('./gfx/paper2.jpg');
      background-position: top;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 12px;
      border: 1px solid #000;
      //bottom: $cardGridPadding;
      // display: flex;
      // justify-content: center;
      //left: $cardGridPadding;
      overflow: hidden;
      //position: absolute;
      //right: $cardGridPadding;
      //top: $cardGridPadding;
      transition: box-shadow .5s ease;
      .cardContent {
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        .title {
          padding: 0 10%;
          font-size: 1.5vw;
          overflow: hidden;
          &.title-long {
            font-size: 1vw;
          }
          @media (max-width: $breakpointL) {
            font-size: 4.5vw;
            &.title-long {
              font-size: 4vw;
            }
          }
        }
      }
    }
    .arrowLeft,
    .arrowRight {
      position: absolute;
      bottom: $cardGridPadding;
      cursor: pointer;
      &:hover {
        color: #666;
      }
    }
    .arrowLeft {
      left: $cardGridPadding;
    }
    .arrowRight {
      right: $cardGridPadding;

    }
  }
}
.cardBody.animation,
.cardBodyBody.animation {
  transition: width .5s ease, left .5s ease, right .5s ease, background-color .5s ease, top .5s ease, border .5s ease, height .5s ease, transform .5s ease .2s;
}

.adminBoard {
  .cardBody,
  .cardEditBody {
    border: 1px solid #eee;
    padding: 10px;
    margin: 4px;
    .cardBodyBody {
      background-repeat: no-repeat;
    }
  }
  .arrowLeft, .arrowRight {
    display: none;
  }
}

$icon-size: 40px;
$ABCDO: 20px;
$ABCDT: 60px;
.magnet-ABCD {
  // background-color: #f00;
  display: block;
  height: 100px;
  // margin: auto;
  width: 1px;
  .magnet-A {
    transform: translate(-$ABCDO, $ABCDT - $ABCDO);
  }
  .magnet-B {
    transform: translate($ABCDO, $ABCDT - $ABCDO);
  }
  .magnet-C {
    transform: translate(-$ABCDO, $ABCDT + $ABCDO);
  }
  .magnet-D {
    transform: translate($ABCDO, $ABCDT + $ABCDO);
  }

}

.magnet {
  opacity: 0;
  transition: 0.5s opacity linear;
  transition-delay: 2s;
  &.floatLeft {
    margin-left: 20px !important;
    margin-top: 0 !important;
    margin-bottom: 30px !important;
  }
  &.floatRight {
    margin-right: 20px !important;
    margin-bottom: 30px !important;
    margin-top: 0 !important;
  }
  &.doCenter {
    display: block;
    margin: 0 auto 40px auto;
    width: 0;
  }
  &:after {
    background-size: contain;
    content: '';
    height: $icon-size;
    position: absolute;
    transform: translate(-$icon-size * 0.5, -$icon-size * 0.5);
    width: $icon-size;
  }
}
.page > div:nth-child(2) .magnet.doCenter {
  margin-top: -10px;
  margin-bottom: 10px;
}

.currentPage .magnet {
  transition-delay: 2s;
  transition: 0.5s opacity linear;
  opacity: 1;
  &.magnet-A:after {
    background-image: url('../../../static/gfx/A-icon.svg')
  }
  &.magnet-B:after {
    background-image: url('../../../static/gfx/B-icon.svg')
  }
  &.magnet-C:after {
    background-image: url('../../../static/gfx/C-icon.svg')
  }
  &.magnet-D:after {
    background-image: url('../../../static/gfx/D-icon.svg')
  }
}

.currentPage.layout-primary-color .magnet {
  &.magnet-A:after {
    background-image: url('../../../static/gfx/A-icon-white.svg')
  }
  &.magnet-B:after {
    background-image: url('../../../static/gfx/B-icon-white.svg')
  }
  &.magnet-C:after {
    background-image: url('../../../static/gfx/C-icon-white.svg')
  }
  &.magnet-D:after {
    background-image: url('../../../static/gfx/D-icon-white.svg')
  }
}

@import '../../static/styles/vars';
$smallBrandMaxWidth: 700px;
.branding {

  // float: right;
  // margin-bottom: 20px;
  // margin-right: 20px;
  // margin-top: -60px;
  // position: relative;
  bottom: 20px;
  height: 40px;
  position: fixed;
  right: 40px;
  width: 200px;
  opacity: 1;
  transition: .4s opacity ease;
  z-index: 2;
  &.hide {
    opacity: 0;
  }
  @media (max-width: $breakpointS) {
    display: none;
  }
  .goldenAspect {

    .brandImg {
      position: absolute;
      left: 0;
      top: 0;
      background-position: bottom right;
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      height: 100%;
    }
    .blackImg {
      background-image: url('../../static/gfx/lubbers-logo.svg');
      @media (max-width: $smallBrandMaxWidth) {
        background-image: url('../../static/gfx/lubbers-logo-small.svg');
      }

    }
    .whiteImg {
      background-image: url('../../static/gfx/lubbers-logo-white.svg');
      @media (max-width: $smallBrandMaxWidth) {
        background-image: url('../../static/gfx/lubbers-logo-small-white.svg');
      }
    }
    .colorImg {
      background-image: url('../../static/gfx/lubbers-logo-yellow.svg');
      @media (max-width: $smallBrandMaxWidth) {
        background-image: url('../../static/gfx/lubbers-logo-small-yellow.svg');
      }
    }
  }
}

// .currentPage
/*
.branding {
  opacity: 1;
  transition: 2s opacity ease .8s;
  z-index: 2;
}
*/

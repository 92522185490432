@import '../../../static/styles/vars';

$bullet-size: 35px;
.bullet {
  cursor: pointer;
  list-style-type: none;
  margin-bottom: 5px;
  margin-top: 5px;
  min-height: $bullet-size;
  padding-left: $bullet-size;
  position: relative;
  transition: background .4s ease;
  width: 100%;
  .marker {
    background-image: url('../../../static/gfx/symbol-B-passive.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: $bullet-size;
  }
  .bullet-content {
    padding: 10px;
    text-align: left;
  }

  // &:hover .marker,
  &:active .marker,
  .marker.active {
    background-image: url('../../../static/gfx/symbol-B.svg');
  }
}
.layout-primary-color .bullet {
  &:active .marker,
  .marker.active {
    background-image: url('../../../static/gfx/B-icon-white.svg');
  }
}

.rc-canvas {
  svg {
    width: 100%;
  }
  .rc-x-axis,
  .rc-y-axis,
  .axis {
    stroke: #000;
    stroke-width: 0.2;
  }
  .rc-area {
    fill: rgba(100, 100, 100, 0.5);
    stroke: green;
    stroke-width: 0.3;
  }
  .axis-label {
    fill: #000;
    font-size: 0.15em;
  }
  .focal-label {
    fill: #000;
    font-size: 0.1em;
  }
  .quadrant-label {
    font-size: 0.1em;
  }
  .tic {
    stroke-width: 0.2;
    stroke: #000;
  }
}
 .radarChartAdmin {
   td {
     font-size: 12px;
     line-height: 14px;
     height: 20px;
     line-height: 1em;
     border-bottom: 1px solid #eee;
   }
 }

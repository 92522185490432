@import '../../../static/styles/vars';
.tab-holder {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: $global-padding;
  flex-wrap: wrap;
  .single-tab {
    background-color: $client-color-1;
    color: $client-color-1-contrast-mark;
    flex-grow: 1;
    padding: $global-padding;
    @include secondaryButton;
    margin-bottom: $global-padding;
    cursor: pointer;
    &:hover {
      background-color: $client-color-3;
    }
  }
  .single-tab-active {
    font-weight: bold;
    @include primaryButton;
  }
}

.tab-count-0:not(.active-tab-0) + .pageElementHolder,
.tab-count-1:not(.active-tab-1) + .pageElementHolder + .pageElementHolder,
.tab-count-2:not(.active-tab-2) + .pageElementHolder + .pageElementHolder + .pageElementHolder
{
  display: none !important;
}



.book-content.standard-theme {
  .pageElement {
    padding-top: 20px;
  }
  @media (min-width: 500px) {
    .pageElement {
      font-size: 20px;
      padding: 20px;
    }
  }
  @media (max-width: 500px) {
    h1 {
      font-size: 20px;
    }
  }
  &.book-content {
    background-color: #fff;
  }
  /*
  primary #fcea17
  black #000
  white #fff
  dark #444a4d
  light #ebedee
  */

  .set-backgroundColor-standard {
    background-color: #fff;
  }
  .set-backgroundColor-light {
    background-color: #fff;
  }

  .set-backgroundColor-dark {
    background-color: #272d2f;
  }

  .set-backgroundColor-special1 {
    background-color: #fcea17;
  }

  .set-backgroundColor-special2 {
    background-color: #eaecee;
  }

  .set-backgroundColor-special3 {
    background-color: #28BAC7;
  }

  .set-backgroundColor-special4 {
    background-color: #1C2E39;
  }

  .set-fontColor-standard {
    color: #fff;
  }
  .set-fontColor-light {
    color: #fff;
  }

  .set-fontColor-dark {
    color: #272d2f;
  }

  .set-fontColor-special1 {
    color: #fcea17;
  }

  .set-fontColor-special2 {
    color: #eaecee;
  }

  .set-fontColor-special3 {
    color: #28BAC7;
  }

  .set-fontColor-special4 {
    color: #1C2E39;
  }

  h1 {
    position: relative;
    &::after {
      color: #fcea17;
      content: '__';
      font-size: 60px;
      left: 0;
      line-height: 50px;
      position: absolute;
      bottom: 14px;
      width: 100%;
    }
  }

  @media (min-width: 992px) {

    .pageElement-index-0 .displayMode > p:first-child {
      &> p,
      &> ul,
      &> h2,
      &> h3,
      &> h4,
      &> blockquote
      {
        &:first-child {
          margin-top: 138px;
        }
      }
    }
    .pageElement-index-0 .collectibleItem {
      margin-top: 63px !important;
    }
    .pageElement-index-0 .adminToolBar {
      margin-top: 60px;
    }
    .pageElement-index-0 .pageElement > p:first-child {
      &> p,
      &> ul,
      &> h2,
      &> h3,
      &> h4,
      &> blockquote
      {
        &:first-child {
          margin-top: 138px;
        }
      }
    }
  }
  // Crumb
  .toc-crumb {
    position: absolute;
    left: 20px;
    top: 25px;
    width: 100%;
    z-index: 3;
    text-transform: uppercase;
    opacity: 0.8;
    font-size: 0.7em;
    .toc-book-name {
      display: inline;
      &:after {
        content: ' - ';
      }
    }
    .toc-chapter-name {
      display: inline;
    }
  }

  .crumbs {
    font-size: 0.8em;
    left: 20px;
    position: absolute;
    top: 17px;
    z-index: 100;
    .bookname {
      text-transform: uppercase;
      text-align: left;
    }
    .crumb {
      font-style: italic;
      .chapternumber {
        display: none;
      }
      &.last .chapternumber {
        display: inline;
      }
      &:not(.first) {
        &:before {
          content: ' - ';
        }
      }
    }
    &.chapter-style-chapter .chapternumber::after {
      display: inline;
      content: ' - ';
    }

  }
}

.standard-theme-2021-09 {

  .layout-kind-quoteBox {
    min-width: 30%;
    max-width: 60%;
    padding: $global-padding;
    background-color: $client-color-1;
    border-radius: 12px;
  
    &::before {
      content: 'zitat';
    }
  
    .textholder {
      column-count: 1 !important;
      display: flex;
      flex-direction: column;
      font-size: 22px;
      font-weight: 600;
      height: 100%;
      justify-content: space-between;
      line-height: 1.09em;
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      h1, p:first-child {
        margin-top: 24px;
      }
      p:last-child {
        font-size: 12px;
        letter-spacing: 0.43px;
        line-height: 1.09em;
        margin-bottom: 0;
      }
    }
  }
  
  .layout-absPosition-lt,
  .layout-absPosition-lm,
  .layout-absPosition-lb,
  .layout-absPosition-rt,
  .layout-absPosition-rm,
  .layout-absPosition-rb,
  .layout-absPosition-ct,
  .layout-absPosition-cm,
  .layout-absPosition-cb {
    position: absolute
  }
  
  $pad: 33px;
  $padSide: 33px;
  
  .layout-absPosition-lt {
    left: $padSide;
    top: $pad;
  }
  .layout-absPosition-lm {
    left: $padSide;
    top: 30%;
  }
  .layout-absPosition-lb {
    left: $padSide;
    bottom: 80px;
  }
  .layout-absPosition-rt {
    top: $pad;
    right: $padSide;
  }
  .layout-absPosition-rm {
    top: 30%;
    right: $padSide;
  }
  .layout-absPosition-rb {
    right: $padSide;
    bottom: 80px;
  }
  .layout-absPosition-ct {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    top: $pad;
  }
  .layout-absPosition-cm {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    top: 30%;
  }
  .layout-absPosition-cb {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    bottom: 80px;
  }
  
  .layout-color-black {
    background-color: black;
    color: white;
  }
  .layout-color-white {
    background-color: white;
    color: black;
  }
  .layout-color-mint {
    background-color: #40d3bc;
    color: white;
  }
  .layout-color-purple {
    background-color: #731dd8;
    color: white;
  }
  .layout-color-darkGreen {
    background-color: #21837f;
    color: white;
  }
  .layout-color-lightGreen {
    background-color: #40d3bc;
    color: white;
  }
  

}  

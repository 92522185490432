.UnitList {
  height: 100%;
  overflow: scroll;
  .unit {
    box-shadow: 1px 1px 6px #aaa;
  }
  .cIcon {
    height: 1em;
    position: relative;
    top: 3px;
    &:hover {
      transform: scale(1.2);
    }
  }
  .UnitListHeader {
    position: fixed;
    top: 0;
    background-color: #fff;
    overflow: visible;
    z-index: 10;
  }
  .UnitListContent {
    padding-top: 50px;
  }
}

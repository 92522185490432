.doublePage {
  min-height: 100%;
  position: relative;

  .DoublePageContent {
    min-height: 100%;
    overflow: hidden;
    @media (max-width: 992px) {
      height: 100%;
      overflow-y: auto;
    }
  }
  .collectibleWarning {
    position: absolute;
    left: 20px;
    top: 20px;
    background-color: yellow;
    color: black;
    z-index: 1000;
  }
}
.DoublePageBackgroundHolder {
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  left: 0;
  min-height: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: 0.2s opacity ease 0.5s;
  width: 100%;
}
.currentPage .DoublePageBackgroundHolder {
  opacity: 1;
  transition: 2s opacity ease .8s;
}
.adminPageHolder {
  // overflow: hidden;
}
@import '../../static/styles/vars';

$cakeARadius: 100px;
$cakeAPieceWidth: 3px;
$markerWidth: 30px;
.cakeA {
  .cakeAPlate {
    height: $cakeARadius * 2;
    width: $cakeARadius * 2;
    position: relative;
    border-radius: 100%;
    box-shadow: 2px 2px 10px #aaa;
    margin: auto;
    .cakeAPiece {
      // background-color: transparent;
      transform-origin: 0 400%;
      left: $cakeARadius;
      top: 0;
      width: $cakeAPieceWidth;
      border-bottom: 1px solid #eee !important;
      // border-top: 2px solid #eee !important;
      border-right: 0px solid transparent;
      border-left: 0px solid transparent;
      border-style: solid;
      position: absolute;
      height: $cakeARadius * 0.25;
      /*
      border-right: $cakeAPieceWidth solid transparent;
      border-bottom: 2px solid #eee;
      border-top: 2px solid #eee;
      border-left: none;
      height: $cakeARadius * 0.25;
      width: 0;
      position: absolute;
      left: $cakeARadius;
      top: 0;
      transform-origin: 0 400%;
      */
      /*
      height: $cakeARadius;
      width: $cakeAPieceWidth;
      position: absolute;
      left: $cakeARadius;
      top: 0;
      transform-origin: 0 100%;
      */
    }

  }
  .cakeAPieceKey-A {
    border-color: $client-color-1;
    background-color: $client-color-1;
  }
  .cakeAPieceKey-B {
    border-color: #fff;
    background-color: #fff;
  }
  .cakeAPieceKey-C {
    border-color: $client-color-3;
    background-color: $client-color-3;
  }
  .cakeAPieceKey-D {
    border-color: $client-color-2;
    background-color: $client-color-2;
  }
  .cakeALegend {
    text-align: left;
    margin: auto;
    padding-left: 20px;
    padding-top: 20px;
    .cakeALegendEntry {

      line-height: $markerWidth;
      .marker {
        // box-shadow: 0 0 4px #eee;
        border: 1px solid #eee;
        display: inline-block;
        height: $markerWidth;
        margin-right: 4px;
        width: $markerWidth;
      }
      .description {
        display: inline-block;
        transform: translateY(-8px);
      }
    }
  }
}

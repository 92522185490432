.libraryLinkButton {
  transition: .5s opacity ease-in-out;
  opacity: 1;
  &.libraryLinkButton--1 {
    opacity: 0;
  }
  .book {
    margin: auto;
    // max-width: 30%;
  }
}
.bookLinkAdmin {
  display: none;
}
.focus-1 .bookLinkAdmin {
  display: block;
}
.infoToolTip {
  cursor: pointer;
  display: inline-block;
  margin-right: 4px;
  position: relative;
  transform: translateY(5px);
}
.contactRules input {
  margin-right: 4px;
}

$bubbleSize: 42px;
$bubbleTBMargin: 10px;
$innerBubbleGap: 4px;
$bubbleBorderWidth: 4px;
$bubbleBorderColor: #000;
$activeInnerColor: #000;
$hoverInnerColor: #000;
$activeEffectColor: #000;
.userInputScale1 {
  padding: 10px 0;
  .scaleSelectionHolder {
    .scaleItem {
      padding: 0;
      cursor: pointer;
      .bubble {
        position: relative;
        border-color: $bubbleBorderColor;
        border-radius: 100%;
        border-style: solid;
        border-width: $bubbleBorderWidth;
        display: block;
        height: $bubbleSize;
        margin: $bubbleTBMargin auto;
        padding-top: $innerBubbleGap;
        width: $bubbleSize;
        .innerBubble {
          background-color: $hoverInnerColor;
          border-radius: 100%;
          display: block;
          height: $bubbleSize - 2 * $innerBubbleGap - 2 * $bubbleBorderWidth;
          margin: auto;
          transform: scale(0);
          transition: 0.1s transform linear;
          width: $bubbleSize - 2 * $innerBubbleGap - 2 * $bubbleBorderWidth;
        }
        &:after {
          content: '';
          left: 0;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          border-radius: 100%;
          transition: 0.8s transform ease, 0.4s opacity ease;
          transform: scale(0);
          opacity: 0.8;
          pointer-events: none;
        }
      }
      &.active .bubble:after {
        border: 4px solid $activeEffectColor;
        transform: scale(10);
        opacity: 0;
      }
      &:hover .innerBubble {
        background-color: $hoverInnerColor;
        transform: scale(1);
      }
      &.active .innerBubble {
        background-color: $activeInnerColor;
        transform: scale(1);
      }
      &:active .innerBubble {
        // background-color: #0a0;
      }
    }
  }
  .scaleDescription {
    padding: 10px;
  }
  .userSays {
    line-height: $bubbleSize;
    text-align: center;
    padding: 0;
    svg {
      height: $bubbleSize;
      width: $bubbleSize;
      position: relative;
      top: $bubbleTBMargin;
      margin: auto;
    }
  }
}

.textarea {
  background-color: #eee;
  border-radius: 20px;
  border: none;
  color: #000;
  // font-family: 'Brush Script MT', cursive;
  // font-size: 2em;
  padding: 10px;
  /*
  &:focus {
    box-shadow: 5px 5px 10px #000;
    border: none;
  }
  */
}
.textareaOkButton {
  margin-top: 10px;
  transform: scale(0);
  transition: transform 0.2s ease;
  border-radius: 12px;

  &.disabled {
    border-color: gray !important;
    opacity: 0.5;
    transform: scale(0);
  }
  &.pleaseSave {
    border-width: 4px;
    transform: scale(1);
  }
}

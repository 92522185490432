@import '../../static/styles/vars';

.UserLogin {
  .w3-btn {
    @include colorSet1;
    line-height: $dashboard-tile-line-height;

  }
  .cursorPointer {
    cursor: pointer;
  }
  .loggedIn,
  .loginDialog {
    max-height: 500px;
    transition: .4s max-height ease;
    overflow: hidden;
  }
  .loginDialog {
    padding: $global-padding;
  }
  &.statusLoggedIn {
    .loginDialog {
      max-height: 0;
    }
  }
  &.statusLoggedOut {
    .loggedIn {
      max-height: 0;
    }
  }
}

.avatar.loggedin svg {
  fill: black;
}

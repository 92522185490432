@import '../../../static/styles/vars';

$cardSortedWidth: 27%;
$cardMiddleWidth: 27%;
$cardWidth: 18%;
$tiltAngle: 10deg;
// $boardHeight: 600px;
// $cardMiddleHeight: $boardHeight * 0.46;
// $cardMiddleTop: $boardHeight * 0.27;

.cardSortGame {
  margin-top: 100px;
  .admin {
    overflow: hidden;
    .stack {
      width: 50%;
      float: left;
    }
  }
  .game {
    // background-image: url('./gfx/wood1.jpg');
    // background-image: url('./gfx/blueWall1.jpg');
    // background-color: #444a4d;
    // background-color: #fcea17;
    background-position: center;
    background-size: cover;
    position: relative;
    padding: 10px;
    border-radius: 10px;
    .underbuttons {
      left: 50%;
      width: $cardWidth;
      margin-left: -(.5 * $cardWidth);
      transform: rotate3d(1, 0, 0, $tiltAngle) translate3d(0, 0, 1px);
      position: absolute;
      top: 0;
      .cardBodyBody {
        background-image: none;
        background-color: transparent !important;
        border: none;
        box-shadow: none !important;
      }
    }
    .resetButton,
    .goToResultPage {
      margin-top: 5px;
    }
    .header {
      transform-style: preserve-3d;
      perspective: 800px;
      overflow: hidden;
      text-align: center;
      z-index: 1;
      @media (max-width: $breakpointL) {
        margin-top: 76%;
      }
      .stackLabel {
        cursor: pointer;
        font-weight: bold;
        max-width: 50%;
        min-width: $cardWidth;
        transform: rotate3d(1, 0, 0, $tiltAngle);
        transition: 0.4s box-shadow ease;
        margin-left: 10px;
        margin-right: 10px;
        border: 1px solid #aaa;
        border-radius: 5px;

      }
      .leftLabel {
        float: left;
        box-shadow: 4px 4px 4px black;
        &:hover {
          box-shadow: 1px 1px 1px black;
        }
      }
      .rightLabel {
        float: right;
        box-shadow: -4px 4px 4px black;
        &:hover {
          box-shadow: -1px 1px 1px black;
        }
      }

    }

    .board {
      z-index: 2;
      position: relative;
      transform-style: preserve-3d;
      perspective: 800px;
      padding-top: 25%;
      @media (max-width: $breakpointL) {
        position: absolute;
        left: -90%;
        right: -90%;
      }
      .leftStack,
      .rightStack {
        width: 33%;
        height: 100%;
        // border: 4px solid #ccf;
        // border: 4px solid #fff;
        //border-radius: 10px;
        // background-image: url('./gfx/yellowCloth1.jpg');
        background-size: cover;
      }
      .activeDrop {
        // background-color: rgba(255, 255, 255, 0.5);
      }
      .leftStack {
        float: left;
      }
      .rightStack {
        float: right;
      }

      .position-none {
        cursor: pointer;
        left: 50% - .5 * $cardWidth;
        top: 0;
        position: absolute;
        &.animation {
          // top: $cardMiddleTop !important;
        }
      }
      .cardBodyBody {
        box-shadow: 2px 2px 10px rgba(0,0,0,0.2);
      }
      .dragging .cardBodyBody {
        box-shadow: 4px 6px 14px rgba(0,0,0,0.9);
      }
      .position-left {
        left: 0;
        position: absolute;
        top: 0;
      }
      .position-right {
        left: 100% - $cardWidth;
        position: absolute;
        top: 0;
      }
    }
    .result {
      table {
        margin: auto;
        box-shadow: 0 0 10px rgba(0, 0, 0, .5);
        border-radius: 10px;
        padding: 20px;
        tr {
          &:nth-Child(even) {
            td, th {
              background-color: #eee;
            }
          }
          td, th {
            padding: 10px 18px;
          }
        }
        .cardResultSymbol {
          display: block;
          // height: 20px;
        }
        .cardResultCount {
          &::before {
            content: '('
          }
          &::after {
            content: ')'
          }
          &.rightCount {
            color: green;
          }
          &.wrongCount {
            color: red;
          }
        }
      }
    }
  }
}

.tooltip {
  color: #fff;
  font-size: 12px;
  height: 0;
  overflow: hidden;
  &:not(.right-edge) {
    left: 0;
  }
  /*
  */
  line-height: 1.33em;
  max-height: 0;
  position: absolute;
  top: 0;
  // width: 300px;
  width: 100%;
  z-index: 0;
  
  &.right-edge {
    right: 0;
    /*
    left: auto;
    width: 100%;
    */
    .tooltipBody {
      right: 0;
      left: auto;
      // width: 300px;
      .tttriangle {
        right: 15px;
        left: auto;
      }
    }
  }
  &.wide {
    width: 600px;
    
  }
  &.small {
    width: 150px;
    
  }
  .tooltipBody {
    bottom: 0;
    max-height: 0;
    opacity: 0;
    // overflow: hidden;
    position: absolute;
    transition: .4s max-height, .4s opacity;
    .tooltipContent {
      background-color: #272d2f;
      border-radius: 6px;
      margin-bottom: 7px;
      margin-left: 8px;
      padding: 4px 7px 6px 7px;
    }

    .tttriangle {
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 8px solid #272d2f;
      bottom: 2px;
      left: 15px;
      position: absolute;
      width: 12px;
    }
  }
  &.tooltip-bottom {
    top: auto;
    bottom: 0;
    .tooltipBody {
      bottom: auto;
      top: 0;
      .tooltipContent {
        margin: 7px 0 0 0;
      }
      .tttriangle {
        top: 2px;
        bottom: auto;
        border-top: none;
        border-bottom: 5px solid #272d2f;
      }
    }
  }

}

@keyframes showToolTip {
  0% {
    z-index: -1;
  }
  90% {
    z-index: 1;
  }
}

*:hover > .tooltip {
  overflow: visible;
  width: 300px;
  z-index: 1;
  animation: .5s showToolTip;
  &> .tooltipBody {
    max-height: 500px;
    opacity: 1;
  } 
}

$s-base-color: #eaecee;
$s-color1: #fcea17;
.spinner-base {
  opacity: 0;
  &.show-spinner-base {
    opacity: 1;
  }
  .spinner-socket {
    width: 56px;
    height: 56px;
    border: 8px solid $s-base-color;
    border-top-color: $s-base-color;
    border-top-color: $s-color1;
    border-radius: 50%;
    position: relative;
    animation: loader-rotate 1s linear infinite;
    top: 50%;
    margin: -28px auto 0 auto;
  }
  span.spinner-socket {
    width: 16px;
    height: 16px;
    display: inline-block;
    border-width: 3px;
    transform: translateY(16px);
    &.normalSize {
      width: 56px;
      height: 56px;
      border-width: 8px;
    }
  }
}
@keyframes loader-rotate {
  0% {
    transform: rotate(0); }
  100% {
    transform: rotate(360deg); } }

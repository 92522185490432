$akzent: #fcea17;
$iconSize: 50px;
.UserRatingWidget {
  padding: 12px 17px 33px 17px;
  border-radius: 15px;
  background-color: #fff;
  .WUR-RRSB-Container {
    display: flex;
    justify-content: center;
    .rating-item {
      flex-shrink: 3;
      flex-grow: 1;
      flex-basis: auto;
      cursor: pointer;
      text-align: center;
      &.ri-active,
      &:hover {
        .rating-icon {
          background-color: #e9e9ea;
        }
      }
      .rating-icon {
        font-size: $iconSize / 2;
        border-radius: 100%;
        // width: $iconSize;
        width: 100%;
        max-width: $iconSize;
        height: $iconSize;
        padding: 6px;
        margin: 4px auto;
      }
      .rating-item-label {
        color: #000;
        font-size: 11px;
        font-weight: bold;
      }
    }
  }
  h2.WUR-title {
    font-size: 22px;
    color: #000 !important;
    margin: 0 !important;
  }
  .yAxis .recharts-cartesian-axis-tick {
    font-size: 21px;
  }
  .xAxis .recharts-cartesian-axis-tick {
    font-size: 11px;
    font-weight: bold;
  }
  .recharts-symbols {
    opacity: 0;
  }
  .recharts-wrapper {
    width: auto !important;
    svg {
      width: 100%;
    }
  }
}
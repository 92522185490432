@import './vars';
.notice-box {
  @include genericBox;
  border: 1px solid $client-color-3;
}
.warning-box {
  @include genericBox;

  background-color: #ffa;
  border: 1px solid #ee9;
  color: #660;

}

.warning-button {
  @include genericButton;
  background-color: #ffa;
  border: 1px solid #ee9;
  color: #660;
}

.danger-button {
  @include genericButton;
  color: #fff;
  background-color: red;
  border: 1px solid red;
  &:hover {
    background-color: #f66;
  }
}

.good-button {
  @include genericButton;
  color: #fff;
  background-color: green;
  border: 1px solid green;
  &:hover {
    background-color: #3a3;
  }
}

.primary-box {
  @include genericBox;
  @include colorSet2Mark;
  button {
    @include genericButton;
    @include colorSet3Mark;
    border: 1px solid $client-color-3-contrast-mark;
  }
}

.info-box {
  @include genericBox;
  @include colorSet1Mark;
  button {
    @include genericButton;
    @include colorSet3;
  }
  .w3-dropdown-hover {
    button {
      border-radius: 0;
      &::after {
        content: "🔽";
      }
    }
  }
}

.primary-button {
  @include primaryButton;
}

.secondary-button {
  @include secondaryButton;
}

.error-box {
  @include genericBox;
  border: 1px solid red;
}

@import '../../../static/styles/vars';

$crossSize: 4px;
$borderWidth: 10px;
$borderStyle: $borderWidth solid rgba(0,0,0,.25);

$colpadding: 0 16px;

/*
display: table-row;
&>div {
  display: table-cell;
}
*/
._cols {
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: stretch;
  &>div {
    flex-grow: 2;
    padding: $colpadding;
    padding-bottom: 16px !important;
  }
  &._col-count-2 {
    &> div {
      width: 50%;
    }
  }
  &._col-count-3 {
    &> div {
      width: 33%;
    }
  }
  &._col-count-4 {
    &> div {
      width: 25%;
    }
  }
  &._col-count-5,
  &._col-count-51,
  &._col-count-511,
  &._col-count-512,
  &._col-count-54,
  &._col-count-542 {
    &> div {
      width: 20%;
    }
  }
  &._cols._col-count-511 {
    position: relative;
    &:before {
      position: absolute;
      left: -6px;
      top: 50%;
      transform: rotate(-90deg) translateX(-40%);
      transform-origin: 0% 50%;
      content: 'Bedingungen'
    }
    &>div {
      min-height: 120px;
    }
  }
  &._cols._col-count-512,
  &._cols._col-count-542 {
    position: relative;
    &:before {
      position: absolute;
      left: -6px;
      top: 50%;
      transform: rotate(-90deg) translateX(-40%);
      transform-origin: 0% 50%;
      content: 'Aufgaben'
    }
    &>div {
      min-height: 120px;
    }
  }
  &._col-count-212 {
    &> div:nth-child(1) {
      width: 40%;
    }
    &> div:nth-child(2) {
      width: 20%;
    }
    &> div:nth-child(3) {
      width: 40%;
    }
  }
  &._col-count-51,
  &._col-count-511,
  &._col-count-512,
  &._col-count-54,
  &._col-count-542 {
    &> div:nth-child(3) {
      position: relative;
      &::after {
        content: ' ';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        margin-left: $borderWidth * .5;
        width: $borderWidth;
        border-left: $borderStyle;
      }
    }
  }
  &._col-count-54,
  &._col-count-542 {
    &> div {
      border-top: $borderStyle;
      padding-top: $borderWidth;
      // margin-top: $borderWidth;
    }
  }
}
@media (min-width: $breakpointM) {
  /*
  .col-count-2 + .pageElementHolder,
  .col-count-2 + .addBlockRoot + .pageElementHolder,
  .col-count-2 + .pageElementHolder + .pageElementHolder,
  .col-count-2 + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder {
    width: 50%;
    display: inline-block;
    vertical-align: top;
    padding: $colpadding;
  }

  .col-count-3 + .pageElementHolder,
  .col-count-3 + .addBlockRoot + .pageElementHolder,
  .col-count-3 + .pageElementHolder + .pageElementHolder,
  .col-count-3 + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder,
  .col-count-3 + .pageElementHolder + .pageElementHolder + .pageElementHolder,
  .col-count-3 + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder {
    width: 33%;
    display: inline-block;
    vertical-align: top;
    padding: $colpadding;
  }

  .col-count-212 + .pageElementHolder,
  .col-count-212 + .addBlockRoot + .pageElementHolder,
  .col-count-212 + .pageElementHolder + .pageElementHolder + .pageElementHolder,
  .col-count-212 + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder {
    width: 40%;
    display: inline-block;
    vertical-align: top;
    padding: $colpadding;
  }
  .col-count-212 + .pageElementHolder + .pageElementHolder,
  .col-count-212 + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder {
    width: 20%;
    display: inline-block;
    vertical-align: top;
    padding: $colpadding;
  }

  .col-count-4 + .pageElementHolder,
  .col-count-4 + .addBlockRoot + .pageElementHolder,
  .col-count-4 + .pageElementHolder + .pageElementHolder,
  .col-count-4 + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder,
  .col-count-4 + .pageElementHolder + .pageElementHolder + .pageElementHolder,
  .col-count-4 + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder,
  .col-count-4 + .pageElementHolder + .pageElementHolder + .pageElementHolder + .pageElementHolder,
  .col-count-4 + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder {
    width: 25%;
    display: inline-block;
    vertical-align: top;
    padding: $colpadding;
  }

  .col-count-5 + .pageElementHolder,
  .col-count-5 + .addBlockRoot + .pageElementHolder,
  .col-count-5 + .pageElementHolder + .pageElementHolder,
  .col-count-5 + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder,
  .col-count-5 + .pageElementHolder + .pageElementHolder + .pageElementHolder,
  .col-count-5 + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder,
  .col-count-5 + .pageElementHolder + .pageElementHolder + .pageElementHolder + .pageElementHolder,
  .col-count-5 + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder,
  .col-count-5 + .pageElementHolder + .pageElementHolder + .pageElementHolder + .pageElementHolder + .pageElementHolder,
  .col-count-5 + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder {
    width: 20%;
    display: inline-block;
    vertical-align: top;
    padding: $colpadding;
    position: relative;
  }

  .col-count-51 + .pageElementHolder,
  .col-count-51 + .addBlockRoot + .pageElementHolder,
  .col-count-51 + .pageElementHolder + .pageElementHolder,
  .col-count-51 + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder,
  .col-count-51 + .pageElementHolder + .pageElementHolder + .pageElementHolder,
  .col-count-51 + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder,
  .col-count-51 + .pageElementHolder + .pageElementHolder + .pageElementHolder + .pageElementHolder,
  .col-count-51 + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder,
  .col-count-51 + .pageElementHolder + .pageElementHolder + .pageElementHolder + .pageElementHolder + .pageElementHolder,
  .col-count-51 + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder {
    width: 20%;
    display: inline-block;
    vertical-align: top;
    padding: $colpadding;
    position: relative;
  }

  .col-count-54 + .pageElementHolder,
  .col-count-54 + .addBlockRoot + .pageElementHolder,
  .col-count-54 + .pageElementHolder + .pageElementHolder,
  .col-count-54 + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder,
  .col-count-54 + .pageElementHolder + .pageElementHolder + .pageElementHolder,
  .col-count-54 + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder,
  .col-count-54 + .pageElementHolder + .pageElementHolder + .pageElementHolder + .pageElementHolder,
  .col-count-54 + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder,
  .col-count-54 + .pageElementHolder + .pageElementHolder + .pageElementHolder + .pageElementHolder + .pageElementHolder,
  .col-count-54 + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder {
    width: 20%;
    display: inline-block;
    vertical-align: top;
    padding: $colpadding;
    position: relative;
    border-top: $borderStyle;
  }
  .col-count-51 + .pageElementHolder + .pageElementHolder + .pageElementHolder,
  .col-count-51 + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder,
  .col-count-54 + .pageElementHolder + .pageElementHolder + .pageElementHolder,
  .col-count-54 + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder + .addBlockRoot + .pageElementHolder {
    &:after {
      content: ' ';
      position: absolute;
      left: 50%;
      top: 0;
      height: 100%;
      min-height: 500px;
      border-right: $borderStyle;
      width: $borderWidth;
    }
  }
  */

}

.admin.i-layer-3 .overlayMaker:not(.overlayMakerOpen) {
  display: contents;
  position: relative;
  left: auto;
  right: auto;
  bottom: auto;
  top: auto;
  .colsClose {
    display: none;
  }
}


.overlayMaker {
  display: none;
  position: fixed !important;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  transform: translateY(-100%);
  background-color: rgba(0, 0, 0, .5);
  z-index: 101 !important;
  transition: all .4s ease-in-out;
  .colsClose {
    z-index: 1002 !important;
    position: fixed !important;
    left: 10px;
    top: 10px;
    float: none !important;
    border-radius: 8px;
  }
  &:not(.overlayMakerOpen) {
    &.col-count-2 + .pageElementHolder,
    &.col-count-2 + .pageElementHolder + .pageElementHolder,
    &.col-count-3 + .pageElementHolder,
    &.col-count-3 + .pageElementHolder + .pageElementHolder,
    &.col-count-3 + .pageElementHolder + .pageElementHolder + .pageElementHolder,
    &.col-count-4 + .pageElementHolder,
    &.col-count-4 + .pageElementHolder + .pageElementHolder,
    &.col-count-4 + .pageElementHolder + .pageElementHolder + .pageElementHolder,
    &.col-count-4 + .pageElementHolder + .pageElementHolder + .pageElementHolder + .pageElementHolder,
    &.col-count-5 + .pageElementHolder,
    &.col-count-5 + .pageElementHolder + .pageElementHolder,
    &.col-count-5 + .pageElementHolder + .pageElementHolder + .pageElementHolder,
    &.col-count-5 + .pageElementHolder + .pageElementHolder + .pageElementHolder + .pageElementHolder,
    &.col-count-5 + .pageElementHolder + .pageElementHolder + .pageElementHolder + .pageElementHolder + .pageElementHolder {
      display: none !important;
    }
  }
  &.col-count-2 + .pageElementHolder,
  &.col-count-2 + .pageElementHolder + .pageElementHolder,
  &.col-count-3 + .pageElementHolder,
  &.col-count-3 + .pageElementHolder + .pageElementHolder,
  &.col-count-3 + .pageElementHolder + .pageElementHolder + .pageElementHolder,
  &.col-count-4 + .pageElementHolder,
  &.col-count-4 + .pageElementHolder + .pageElementHolder,
  &.col-count-4 + .pageElementHolder + .pageElementHolder + .pageElementHolder,
  &.col-count-4 + .pageElementHolder + .pageElementHolder + .pageElementHolder + .pageElementHolder,
  &.col-count-5 + .pageElementHolder,
  &.col-count-5 + .pageElementHolder + .pageElementHolder,
  &.col-count-5 + .pageElementHolder + .pageElementHolder + .pageElementHolder,
  &.col-count-5 + .pageElementHolder + .pageElementHolder + .pageElementHolder + .pageElementHolder,
  &.col-count-5 + .pageElementHolder + .pageElementHolder + .pageElementHolder + .pageElementHolder + .pageElementHolder
  {
    position: fixed !important;
    width: 50%;
    bottom: 5%;
    top: 65px;
    overflow: auto;
    overflow-x: none;
    z-index: 101 !important;
    padding-left: 5%;
    padding-right: 5%;
  }
  
  &.col-count-2 + .pageElementHolder,
  &.col-count-3 + .pageElementHolder,
  &.col-count-4 + .pageElementHolder,
  &.col-count-5 + .pageElementHolder {
    // left: 5%;
    left: 0;
  }
  &.col-count-2 + .pageElementHolder + .pageElementHolder,
  &.col-count-3 + .pageElementHolder + .pageElementHolder,
  &.col-count-4 + .pageElementHolder + .pageElementHolder,
  &.col-count-5 + .pageElementHolder + .pageElementHolder
  {
  // right: 5%;
  right: 0;
  }
  &.col-count-3 + .pageElementHolder + .pageElementHolder + .pageElementHolder,
  &.col-count-4 + .pageElementHolder + .pageElementHolder + .pageElementHolder,
  &.col-count-4 + .pageElementHolder + .pageElementHolder + .pageElementHolder + .pageElementHolder,
  &.col-count-5 + .pageElementHolder + .pageElementHolder + .pageElementHolder,
  &.col-count-5 + .pageElementHolder + .pageElementHolder + .pageElementHolder + .pageElementHolder,
  &.col-count-5 + .pageElementHolder + .pageElementHolder + .pageElementHolder + .pageElementHolder + .pageElementHolder
  {
    // right: 5%;
    right: 0;
    top: 140px;
  }
  &.overlayMakerOpen {
    display: block;
    transform: translateY(0);
    &.col-count-2 + .pageElementHolder,
    &.col-count-2 + .pageElementHolder + .pageElementHolder,
    &.col-count-3 + .pageElementHolder,
    &.col-count-3 + .pageElementHolder + .pageElementHolder,
    &.col-count-3 + .pageElementHolder + .pageElementHolder + .pageElementHolder,
    &.col-count-4 + .pageElementHolder,
    &.col-count-4 + .pageElementHolder + .pageElementHolder,
    &.col-count-4 + .pageElementHolder + .pageElementHolder + .pageElementHolder,
    &.col-count-4 + .pageElementHolder + .pageElementHolder + .pageElementHolder + .pageElementHolder,
    &.col-count-5 + .pageElementHolder,
    &.col-count-5 + .pageElementHolder + .pageElementHolder,
    &.col-count-5 + .pageElementHolder + .pageElementHolder + .pageElementHolder,
    &.col-count-5 + .pageElementHolder + .pageElementHolder + .pageElementHolder + .pageElementHolder,
    &.col-count-5 + .pageElementHolder + .pageElementHolder + .pageElementHolder + .pageElementHolder + .pageElementHolder
    {
      display: block;
    }
  }
}



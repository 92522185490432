@import '../../static/styles/vars';


.SvgEditor {
  background: #222;
  width: 100%;
  height: 100%;
  display: block;

  .typedemo {
    pointer-events: none;
  }

  .canvas {
    width: 100%;
    height: 500px;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .canvas svg {
    // width: 500px;
    max-height: 99%;
  }

  .canvas button {
    background: #444;
    border-radius: 0;
    color: white;
    font-size: 1rem;
    border: 0;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    box-shadow: 1px 1px 0 1px rgba(0, 0, 0, 0.2);
    outline: 0;
  }

  .canvas button.active {
    background: black;
    box-shadow: none;
    position: relative;
    top: 1px;
  }

  .canvas svg.tool--circ,
  .canvas svg.tool--rect {
    cursor: crosshair;
  }

  .canvas svg.tool--drag {
    cursor: default;
  }

  .canvas svg *:hover {
    stroke: dodgerblue;
    stroke-width: 2px;
  }
  .bottomTools {
    height: 40%;
    overflow: auto;
    background-color: #999;
    padding: 10px;
  }
  button.active  {
    background-color: #fff;
  }
  button.disabled svg {
    stroke: #999;
  }
  .upperToolBar .toolbar {
    max-width: 400px;
    margin: auto;
  }
}
.lastColors {
  .oldColor {
    float: left;
    width: 42px;
    height: 42px;
    border: 1px solid #aaa;
  }
}

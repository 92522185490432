.booksurvey {
  height: 1000px;
  overflow: auto;
  .BSshowSpoiler {
    float: left;
    overflow: visible;
    border: 1px solid #666;
    clear: right;
    &:hover .BSspoiler {
      display: block;
    }
  }
  .BSspoiler {
    display: none;
  }
  table {
    width: 100%;
  }
}

@import '../../static/styles/vars';

.Library {
  height: 100%;
  // hyphens: auto;
  // padding: 0 $dashboard-tile-gap*.5;
  &::after {
    // background-color: #fff;
    content: '';
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: .8s opacity ease .2s;
    z-index: 10001;
  }
  transition: 1s blur ease .7s;
  .book-box {
    @include tileOuter;
    perspective: 1000px;
    transition: 1s transform ease .2s;
    &:hover {
      z-index: 100;
    }
    .book {
      @include tileInner;
      &:hover {
        z-index: 100;
      }
      transition: 1s transform ease, .2s box-shadow ease;
      // transform-origin: left;
      transform-style: preserve-3d;
      &:hover {
        // box-shadow: 6px 6px 10px #666;
        transform-origin: center;
        transform: scale(1.05);
        transition: .4s transform ease;
      }
      .bookPicture {
        @include colorSet1;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: $dashboard-tile-border-radius;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        transform: translateZ(-1px);
        width: 100%;
      }
      .teaserBook {
        box-shadow: 0 0 10px #999;
        left: 10px;
        margin: 10px;
        position: absolute;
        top: 50%;
        transform: rotate(-4deg);
        display: none;
      }
      .addToCart {
        left: 10px;
        bottom: 10px;
        position: absolute;
        .cartIcon {
          width: 32px;
          height: 32px;
          margin-right: 10px;
        }
        .bookIcon {
          width: 21px;
          height: 21px;
          position: absolute;
          left: 9px;
          top: -1px;
          opacity: 0;
          transform: translateY(-40px);
          transition: .5s opacity ease-in, .4s transform ease-in;
        }
        .price {
          font-size: 18px;
          bottom: 2px;
          position: relative;
        }
        &.bookInCart {
          .bookIcon {
            transform: translateY(0);
            opacity: 1;
          }
        }
      }
      .lastSeenLabel {
        font-size: 14px;
        font-weight: 500;
        left: $dashboard-tile-padding;
        position: absolute;
        right: $dashboard-tile-padding;
        top: 53%;
      }
    }
    .bookTextContent {
      // bottom: $dashboard-tile-bottom-padding;
      left: $dashboard-tile-padding;
      overflow: hidden;
      padding-bottom: 5px;
      position: absolute;
      right: $dashboard-tile-padding;
      top: 60%;
      .title {
        @include tileTitle;
        backface-visibility: hidden;
      }
    }
  }
  .big-tile .book-box .bookTextContent .title {
    @include tileTitleBig;
  }
  .lastPosition {
    bottom: $dashboard-tile-padding;
    font-size: $dashboard-tile-marker-font-size;
    position: absolute;
    right: $dashboard-tile-padding;
  }
  .bookFront {
    cursor: pointer;
  }
  .bookFront,
  .bookStartingPoints,
  .bookFirstPageOpen {
    @include tileShadow;
    backface-visibility: hidden;
    border-radius: $dashboard-tile-border-radius;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 1s transform ease, .2s box-shadow ease;
  }
  .bookStartingPoints {
    padding: 6*$global-padding $global-padding $global-padding;
    @include colorSet3Mark;
    font-size: 2em;
    .closeButton {
      cursor: pointer;
      position: absolute;
      right: $global-padding;
      top: $global-padding;
    }
    button {
      @include colorSet4;
      cursor: pointer;
      display: block;
      padding: $global-padding;
      width: 100%;
    }
  }

  .bookFirstPageOpen {
    padding: 6*$global-padding $global-padding $global-padding;
    font-size: 2em;
    line-height: 2em;
    background-color: #fff;
  }
  .bookStartingPoints,
  .bookFirstPageOpen {
    transform: rotateY(180deg);
  }

  .flip {
    z-index: 100;
    .bookFront {
      transform: rotateY(-180deg);
    }
    &.showStartingPoints .bookStartingPoints {
      transform: rotateY(0deg);
    }
    &.showOpen .bookFirstPageOpen {
      transform: rotateY(0deg);
    }
  }

  .zoomIn .book {
    transition-delay: .5s !important;
    transform: translate3d(0, 0, 900px) !important;
  }

}

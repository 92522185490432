$breakpointXl: 1500px;
$w1: 8.33333%;
$w2: 16.66666%;
$w3: 24.99999%;
$w4: 33.33333%;
$w5: 41.66666%;
$w6: 49.99999%;
$w7: 58.33333%;
$w8: 66.66666%;
$w9: 74.99999%;
$w10: 83.33333%;
$w11: 91.66666%;
$w12: 99.99999%;
$w1half: $w1 * .5;
// w3-additional rules:


@media (max-width: 600px) {
  .d-s-show {
    display: block;
  }
  .d-s-hide {
    display: none;
  }
  .p-s-1 {
    padding-left: $w1;
    padding-right: $w1;
  }
}


@media (min-width: 601px) and (max-width: 992px) {
  .d-m-show {
    display: block;
  }
  .d-m-hide {
    display: none;
  }
  .p-m-1 {
    padding-left: $w1;
    padding-right: $w1;
  }
}

@media (min-width: 993px) and (max-width: $breakpointXl - 1px) {
  .d-l-show {
    display: block;
  }
  .d-l-hide {
    display: none;
  }
  .p-l-1 {
    padding-left: $w1;
    padding-right: $w1;
  }
}

@media (min-width: $breakpointXl) {
  .w3-col {
    &.xl1 {
      width: $w1;
    }
    &.xl2 {
      width: $w2;
    }
    &.xl3 {
      width: $w3;
    }
    &.xl4 {
      width: $w4;
    }
    &.xl5 {
      width: $w5;
    }
    &.xl6 {
      width: $w6;
    }
    &.xl7 {
      width: $w7;
    }
    &.xl8 {
      width: $w8;
    }
    &.xl9 {
      width: $w9;
    }
    &.xl10 {
      width: $w10;
    }
    &.xl11 {
      width: $w11;
    }
    &.xl12 {
      width: $w12;
    }
  }
  .d-xl-show {
    display: block;
  }
  .d-xl-hide {
    display: none;
  }
  .p-xl-1 {
    padding-left: $w1 * .5;
    padding-right: $w1 * .5;
  }
}
